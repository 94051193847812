.publiclayout {
  padding: 10px 75px 20px 75px;
  @media (max-width: 1000px) {
    padding: 10px 30px 20px 30px;
  }
  &-content {
    padding: 0 40px;
    @media (max-width: 1000px) {
      padding: 0;
    }
  }
  &-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-logo {
      width: 90px;
      height: 60px;
    }
  }
  &-footer {
    width: 80%;
    position: fixed;
    bottom: 15px;
    left: 10%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 625px) {
      position: relative;
      width: 100%;
      margin-top: 25px;
      bottom: 0;
      left: 0;
    }
  }
  &-main-footer {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    left: 0;
  }
  .hr-white {
    border-top: 1px solid white !important;
  }
  .hr-black {
    border-top: 1px solid black !important;
  }
}

.filestable {
  .close-icon-file {
    color: #3C5DD6;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: #3C5DD6 solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
  }

  .dragable {
    border: 2px #969696;
    border-style: dashed;
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    cursor: pointer;

    &.drag-over {
      border: 3px dashed #3C5DD6;
    }

    .anticon {
      color: #3C5DD6;

      svg {
        font-size: 30px;
      }
    }
  }
}

.files-container {
  max-height: 120px;
  overflow-y: auto;

  .ant-row {
    .ant-col {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
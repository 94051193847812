.general {
  .select-class-multiple {
    border: 1px solid #ecf7ff;
    border-radius: 6px;
    height: 40px;
    width: 100%;
    padding: 5px;
    background-color: #fff;
  }

  .select-class-multiple {
    height: auto;
  }

  .borderArrayIcon {
    border: 1px solid;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .btn {

    &-edit,
    &-save,
    &-cancel {
      border: 2px solid #3c5dd6;
      border-radius: 8px;
      font-size: 14px;
      padding: 5px 10px;
      color: #fff;
      background-color: #3c5dd6;
      cursor: pointer;
    }
  }
}
.CategoriesDropdowns {
  &-container {
    color: white;
    border-radius: 8px;
    padding: 16px;
    margin-top: 15px;
  }

  .border-top-none {
    border-radius: 8px 8px 0 0;
  }

  .ProjectsLIst {
    .ant-row {
      +.ant-row {
        margin-top: 16px;
      }
    }
  }
}
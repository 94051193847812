@import '../../styles/abstracts/controls';
.container,
.containerFluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  @include transition(all 0.8s ease-in-out);
  @include media('<phone') {
    max-width: 100%;
    min-width: 100%;
  }
  @include media('>=phone', '<576px') {
    max-width: 100%;
    min-width: 100%;
  }
  @include media('>=576px', '<=tablet') {
    max-width: 100%;
    min-width: 100%;
  }
  @include media('>=tablet', '<=992px') {
    max-width: 100%;
    min-width: 100%;
  }
  @include media('>=992px', '<desktop') {
    max-width: 100%;
    min-width: 100%;
  }
  @include media('>=desktop', '<=1366px') {
    max-width: 1140px;
  }
  @include media('>1366px') {
    max-width: 1340px;
  }
}

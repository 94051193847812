.selectedmember {
  display: flex;
  border-radius: 50px;
  padding: 5px;
  position: relative;

  .avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: auto 5px;
  }
}

.deleteIconMember {
  cursor: pointer;
  margin: auto 0;
  margin-right: 5px;
  font-size: 20px;
}

.h-300 {
  height: 300px;
}

.debounceloading {
  position: fixed;
  bottom: 30px;
  right: 30px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  padding: 7px 10px;
  border-radius: 5px;
  z-index: 999999;
}

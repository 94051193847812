@import "../../styles/abstracts/controls";
.userSettings {
  @extend %flex-column;
  .profileLayout {
    .settingsLeft {
      @extend %flex-column;
      &__top {
        flex: 0 1 auto;
        max-height: 200px;
        overflow: hidden;
        img {
          width: 100%;
          opacity: 0.75;
        }
      }
      &__picture {
        object-fit: cover;
        height: 100%;
      }
      .profilePicBlock {
        padding: 25px 80px;
        @media (max-width: 769px) {
         display: flex;
         justify-content: center;
        }
        height: 55px;
        .userImage {
          display: flex;
          max-width: 135px;
          min-width: 135px;
          max-height: 135px;
          min-height: 135px;
          overflow: hidden;
          background-color: theme-colors("background", "white", 0.9);
          @include border-radius(50%);
          @extend %flex-column;
          @extend %all-center;
          @include box-shadow(0 2px 8px theme-colors("primary", "light", 0.2));
          @include transform(translate(0, -100px));
          // styled edit overlay from here
          span {
            flex: 1 1 auto;
            padding: 1px;
            max-width: 135px;
            min-width: 137px;
            max-height: 70px;
            min-height: 70px;
            @extend %flexbox;
            @extend %all-center;
            img {
              @extend %radius-sm;
            }
          }
          .editOverlay {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
            background-color: theme-colors("primary", "dark", 0.5);
            color: bisque;
            @extend %flexbox;
            @extend %all-center;
            @include transition(all 0.8s ease-in-out);
          }
          &:hover {
            margin: 0;
            .editOverlay {
              height: 70%;
              opacity: 1;
              @include transition(all 0.4s ease-in-out);
            }
          }
          /// to here

          .profileImage {
            flex: 1 1 auto;
            max-width: 130px;
            min-width: 130px;
            max-height: 130px;
            min-height: 130px;
            background-color: theme-colors("background", "white", 0.9);
            @include border-radius(50%);
            @extend %flex-column;
            @extend %all-center;
            img {
              flex: 1 1 auto;
              max-width: 130px;
              min-width: 130px;
              max-height: 130px;
              min-height: 130px;
              @include border-radius(50%);
            }
          }
        }
      }
      .settingsList {
        padding: 0.06rem 0;
        overflow: hidden;
        background-color: theme-colors("background", "white");
        @extend %flex-column;
        border-radius: 0 0 0.7rem 0.75rem;
        @include box-shadow(0 1px 2px theme-colors("primary", "light", 0.03));
        li {
          flex: 1 1 auto;
          @extend %flex-column;
          a {
            flex: 1 1 auto;
            padding: 0.75rem 1rem;
            cursor: pointer;
            &.active {
              background-color: theme-colors("primary", "light", 0.1);
            }
            &:hover {
              background-color: theme-colors("primary", "light", 0.03);
            }
          }
        }
      }
    }
    .settingsRight {
      flex: 1 1 auto;
      @extend %flex-column;
      &__tab {
        margin-bottom: 1rem;
        border-bottom: solid 1px theme-colors("primary", "light", 0.3);
        @extend %flexbox;
        @extend %item-center;
        li {
          flex: 0 1 auto;
          @extend %flexbox;
          @extend %item-center;
          &:not(:last-child) {
            margin-right: 30px;
          }
          a {
            flex: 1 1 auto;
            padding: 0.75rem 0;
            cursor: pointer;
            font-weight: 600;
            border-bottom: 2px solid transparent;
            &.active {
              border-bottom-color: theme-colors("primary", "light", 0.7);
            }
          }
        }
      }
      .userDetails {
        @extend %flex-column;
        li {
          padding: 10px 0;
          @extend %flexbox;
          @extend %item-center;
          label {
            min-width: 200px;
            font-weight: 600;
            @media (max-width: 599px) {
              min-width: 160px;
              font-weight: 560;
            }
          }
        }
        &__button,
        &__password {
          display: flex;
          align-self: end;
          &__btn {
            background-color: #3d5dd6;
            color: #fff;
          }
        }
      }
      .password__list {
        li {
          padding: 10px 0;
          display: flex;
          @media (max-width: 599px) {
            display: block;
          }

          label {
            min-width: 200px;
            font-weight: 600;
          }
        }
        span {
          flex: 1;
          p {
            width: 350px;
          }
        }
        li:nth-child(1) {
          @extend %item-center;
        }
        li:nth-child(4) {
          @extend %item-center;
        }
      }
    }
  }
  &-tabs {
    width: 75%;
  }
}

.Milestones {
  background-color: #e3e3f4;
  padding: 25px;
  &-btn {
    color: blue;
    padding: 15px;
    border: 1px solid blue;
    background-color: white;
  }
  &-card {
    padding: 15px 25px 40px 25px;
    background-color: #ecf7ff;
    width: 50%;
    height: 30vh;
  }
}

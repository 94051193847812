.tcpmodal {
  margin-top: 15px;
  .hoverIcon {
    display: inline-block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    color: black;
    background-color: transparent;
    border: 2px solid #969696;
    cursor: pointer;
    &::after {
      content: "i";
      color: #969696;
      display: flex;
      font-size: 10px;
      justify-content: center;
      align-items: center;
    }
  }
}

@import "../../../../../styles/abstracts/controls";

.memberdata {
  .ant-modal-header {
    background: #102431 !important;
  }

  .ant-modal-title {
    color: white !important;
  }

  .ant-modal-body {
    padding: 0 24px;
  }

  .opc-5 {
    opacity: 0.5;
  }

  .top-16 {
    top: 16px;
  }

  .left-115 {
    left: 115%;
  }

  .tabs {
    padding: 8px 10px 2px 10px;
    background-color: #ecf7ff;
    border-radius: 15% 15% 0 0;
  }

  .w-100-calc {
    width: calc(100% - 75px);
  }

  .tab-options {
    max-width: 100%;
    overflow-x: auto;

    >p {
      flex-grow: 0;
      white-space: nowrap;
    }
  }

  .deleteIcon {
    color: red;
    font-size: 18px;
    margin-left: 12px;
  }

  .textarea-member {
    border: 1px solid #b0b0b0;
    border-radius: 15px;
    resize: none;
    height: 100px;
    width: 100%;
    padding: 5px;
  }

  .closeIcon {
    top: 0;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  .border-error {
    border-bottom: 1px solid rgba(255, 0, 0, 0.281);
  }

  .input-class {
    border: 1px solid #b0b0b0;
    border-radius: 6px;
    height: 40px;
    width: 100%;
    padding: 5px;
    background-color: #fff;
  }

  .ant-select {
    .ant-select-selector {
      min-height: auto !important;
      border: none !important;
    }
  }

  form {
    .delete-btn {
      background: #888;
    }

    .ant-select-selector {
      padding: 0 !important;
    }

    .p-5 {
      padding: 5;
    }
  }

  .border-rounder {
    border-radius: 50%;
  }

  .avatar-position {
    bottom: -50px;
    left: 15px;
  }

  .avatar {
    width: 60px;
    height: 60px;

    &:hover {
      .editOverlay {
        height: 50%;
        opacity: 1;
        @include transition(all 0.4s ease-in-out);
      }
    }
  }

  .editOverlay {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    background-color: theme-colors("primary", "dark", 0.5);
    color: bisque;
    @extend %flexbox;
    @extend %all-center;
    @include transition(all 0.8s ease-in-out);
  }

  .ant-select-selection-placeholder {
    color: #8a949a;
  }
}

.modal-delete-btn,
.modal-dont-delete,
.tab-desactive {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #3c5dd6;
  color: #3c5dd6;
  padding: 8px;
}

.modal-delete-btn {
  &:hover {
    background-color: rgba(191, 191, 191, 0.105);
  }
}

.modal-dont-delete {
  background-color: #3c5dd6;
  color: #fff;

  &:hover {
    background-color: #2b4399;
  }
}

.w-80-animate {
  width: 80% !important;
  transition: all 0.2s ease-in;
}

.w-60-animate {
  width: 60% !important;
  transition: all 0.2s ease-in;
}

.w-50-animate {
  width: 50% !important;
  transition: all 0.2s ease-in;
}

.closeModal {
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
}

.text-end {
  text-align: end;
}

.bg-modal-click {
  position: fixed;
  width: 100%;
  height: 100%;
  background: red;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
.calendarheader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    justify-items: center;
    background-color: #fff;
    border-radius: 12px;
    margin: 10px 0;
    padding: 0 15px;
    .next,
    .prev {
        width: 50px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        cursor: pointer;
        transition: 0.7s;
        img {
            width: 20px;
        }
    }
    .prev {
        transform: rotate(180deg);
    }
}

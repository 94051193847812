.primarydrivers {
  width: 100%;
  &-card {
    width: 90%;
    padding: 15px;
    border-radius: 8px;
    background-color: #8fc9f6;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-percentage {
      background-color: white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
    }
  }
  .top-15 {
    top: 15px;
  }

  .bottom-15 {
    bottom: 15px;
  }

  .sticky {
    position: sticky;
  }

  .l-14 {
    left: 14px;
  }

  .h-300 {
    height: 300px;
  }

  .h-380 {
    height: 380px;
  }
}

.mainsearch {
  overflow: hidden;
  position: relative;
  .h-45 {
    height: 45%;
  }
  .hr-yellow {
    border-top: 2px solid #f3c00b;
    width: 25%;
    margin: auto;
    position: relative;
    top: 50px;
  }
  .collapse {
    .ant-collapse .ant-collapse-item .ant-collapse-header {
      background-color: transparent !important;
      color: white;
    }
  }
  .h-300px {
    height: 300px;
  }
  .h-650px {
    height: 650px;
  }
  &-related-search {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 20px;
    overflow: scroll;
    &-container {
      width: 45%;
      height: 100%;
      margin-left: 15px;
      border-radius: 20px;
    }
    &-btn {
      width: 100%;
      color: white;
      border-radius: 20px;
      background-image: linear-gradient(95deg, #30def7 6.08%, #f3c00b 96.58%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      position: relative;
      border: none;
      outline: none;
      cursor: pointer;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border-radius: 20px;
        background-color: black;
        z-index: -1;
      }
    }
  }
  &-container {
    overflow: hidden;
    padding: 0 130px;
    margin: 115px 0 30px 0;
    opacity: 1;
    @media (max-width: 1000px) {
      margin: 0;
      margin-bottom: 10px;
      padding: 0 35px;
    }
    @media (max-width: 600px) {
      margin: 0;
      padding: 0;
      margin-bottom: 10px;
    }
    &-p {
      @media (max-width: 1000px) {
        font-size: 12px;
      }
    }
    &-title {
      color: #f3c00b;
      @media (max-width: 1000px) {
        font-size: 16px;
      }
    }
    &-search {
      margin: auto;
      margin-bottom: 15px;
      width: 90%;
      @media (max-width: 765px) {
        width: 100%;
      }
      &-bar {
        .ant-input {
          background-color: transparent !important;
          border: 1px solid white;
          color: white;
          border-radius: 118.343px !important;
          font-size: 30px;
          height: 60px;
          &::placeholder {
            color: #fff;
          }
          &:hover {
            color: #fff;
          }
        }
        .ant-input-group-addon {
          background-color: transparent;
          button {
            background: linear-gradient(95deg, #30def7 6.08%, #f3c00b 96.58%);
            font-size: 25px;
            width: 50px;
            height: 45px;
            border-radius: 50% !important;
            position: absolute;
            left: auto;
            right: 8px;
            top: 8px;
          }
        }
      }
    }
    &-loading {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
  }
  &-slider {
    opacity: 1;
    border-radius: 15px;
    margin: auto;
    padding: 50px;
    @media (max-width: 650px) {
      width: 100%;
    }
    &-border {
      border: 1px solid white;
      border-radius: 15px;
      height: 150px;
      @media (max-width: 1140px) {
        height: auto;
      }
    }
  }
  &-bg-black {
    /* Set a specific height */
    min-height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(180deg, #000000 0%, #3d3d3d 100%);
  }
  &-bg-white {
    /* Set a specific height */
    min-height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(180deg, #f3f5f5 34.96%, #c9d6de 115.23%);
  }
  .h-0 {
    height: 0;
  }
  .talk-btn,
  .search-btn,
  .talk-btn-slide,
  .search-btn-slide {
    cursor: pointer;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #0478c6;
    color: #0478c6;
    padding: 8px;
  }

  .talk-btn-slide,
  .search-btn-slide {
    padding: 6px;
  }

  .talk-btn,
  .talk-btn-slide {
    &:hover {
      background-color: rgba(191, 191, 191, 0.105);
    }
  }
  .talk-btn,
  .talk-btn-slide {
    background-color: #0478c6;
    color: #fff;
    &:hover {
      background-color: #2b4399;
    }
  }
  .talk-btn-slide {
    background-color: transparent !important;
    border: 1px solid white;
  }
  .talk-btn {
    background: linear-gradient(95.27deg, #30def7 6.08%, #f3c00b 96.58%);
  }
  .btn-container-postion {
    display: flex;
    justify-content: center;
  }
  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0;
  }
  .slick-prev::before,
  .slick-next::before {
    color: black !important;
  }
}

.staff {
  &-card {
    position: relative;
    background-color: white;
    border-radius: 15px;
    height: 265px;
    padding: 25px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(12, 35, 120, 0.25);
    margin-top: 15px;
    &-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 0 15px;
    }
    &-btn, &-btn-r{
      cursor: pointer;
      padding: 2px;
      color: #fff;
      border: 1px solid #3c5dd6;
      background-color: #3c5dd6;
      border-radius: 5px;
      &:hover {
        background-color: #2b4399;
      }
    }

    &-btn-r {
      border: 1px solid #3c5dd6;
      color: #3c5dd6;
      background-color: white;
      &:hover {
        background-color: rgb(196, 196, 196);
      }
    }
    &-name {
      color: #3c5dd6;
      font-size: 20px;
    }
    &-archetype {
      display: block;
      margin: 5px auto;
      color: #3c5dd6;
      font-size: 12px;
      background-color: #c8e6fd;
      padding: 1px 8px;
      width: 90%;
      height: 24px;
      border-radius: 15px;
      cursor: pointer;
    }

    &-option {
      display: flex;
      position: absolute;
      bottom: 5px;
      width: 100%;
      left: 0;
    }
  }
  &-no-data {
    display: grid;
    place-items: center;
    width: 100%;
    height: 250px;
    margin-top: 5px;
    background-color: white;

  }
  &-select-class {
    border: 1px solid #d5ecfd;
    border-radius: 6px;
    height: 40px;
    width: 100%;
    background-color: #fff !important;
    margin-bottom: 15px !important;
  }
}

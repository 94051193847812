.daycard {
    display: flex;
    width: 100%;
    justify-content: space-around;
    text-align: center;
    .month,
    .dayNumber {
        font-size: 18px;
        color: #626161;
    }
    .dayNumber {
        color: #000;
    }
    .select-day, .days {
        background-color: #FFD27D;
        border-radius: 10px;
        width: 70px;
        height: 80px;
        margin: 8px 0;
    }
    .days {
        background-color: #fff;
    }
}

.measures {
  background-color: white;

  textarea {
    margin-top: 0;
  }

  .ant-input {
    min-height: 24px !important;
    padding-right: 3px !important;
  }

  .btn-edit-measure {
    padding: 0;
    width: 24px;
    height: 24px;
    min-height: auto;
    border: none;
  }
}

.modal-edit-metrics {
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: #000000 !important;
    }

    &:hover {
      .ant-tabs-tab-btn {
        color: #ffffff !important;
      }
    }
  }

  .ant-table {
    table {
      tr {
        &:nth-child(odd) {
          background-color: #f5f5f5;
        }
      }
    }
  }
}
@import '../abstracts/controls';
.blu-vertical-list {
  @extend %flex-column;
  li {
    flex: 1 1 auto;
    @extend %flex-column;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    h4 {
      font-size: 1.25rem;
      margin-bottom: 0.25rem;
      color: theme-colors('primary', 'base');
    }
    label {
      @extend %flexbox;
      @extend %item-center;
      span {
        flex: 0 0 auto;
      }
    }
    .split-block {
      flex: 1 1 auto;
      @extend %flexbox;
      @extend %item-center;
      .split-half {
        flex: 1 1 50%;
        max-width: 50%;
        @extend %flex-column;
        margin-right: 1.5rem;
      }
    }
    .split-big {
      flex: 1 1 auto;
      @extend %flex-column;

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    .split-small {
      flex: 0 0 auto;
      @extend %flex-column;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
.blu-text-list {
  margin: 0;
  @extend %flex-column;
  li {
    flex: 1 1 auto;
    padding: 1rem;
    border-bottom: solid 1px theme-colors('primary', 'light', 0.1);

    @extend %flexbox;
    @extend %item-center;
    &:first-child {
      border-top: solid 1px theme-colors('primary', 'light', 0.1);
    }
    &:nth-child(even) {
      background-color: theme-colors('primary', 'light', 0.04);
    }
    label {
      flex: 0 0 auto;
      margin: 0;
    }
    span {
      flex: 0 0 auto;
      margin-left: 8px;
      font-weight: 500;
    }
    .answer-block {
      flex: 1 1 auto;
      @extend %flex-column;
      span {
        flex: 0 0 auto;
        margin: 0;
        font-weight: 500;
        @extend %break-text;
      }
      h4 {
        font-size: 1rem !important;
        margin-bottom: 0.25rem;
        font-weight: 500;
        color: theme-colors('primary', 'base', 0.8);
      }
    }
  }
}
.blu-horizontal-list {
  margin: 0;
  @extend %flexbox;
  @extend %item-center;
  li {
    flex: 0 0 auto;

    @extend %flex-column;
    &:not(:last-child) {
      margin-right: 2rem;
    }

    label {
      flex: 1 1 auto;
      margin: 0;
    }
  }
}
.objective-item {
  @extend %flex-column;
  li {
    flex: 1 1 auto;
    padding: 1rem 0;
    @extend %flexbox;
    @extend %item-center;
    &:not(:last-child) {
      border-bottom: solid 1px theme-colors('primary', 'light', 0.1);
    }
    .horizontal-block {
      flex: 1 1 auto;
      @extend %flexbox;
      @extend %item-center;
      &__item {
        flex: 1 1 auto;
        margin-right: 1rem;
      }
      label {
        flex: 0 auto;
        margin: 0;
      }
    }
    .objective-box {
      flex: 1 1 auto;
      @extend %flexbox;
      @extend %item-center;
    }
  }
}
.quests-list {
  margin: 0;
  counter-reset: my-awesome-counter;
  @extend %flex-column;
  li {
    flex: 1 1 auto;
    padding: 1rem 0 1rem 1.5rem;
    position: relative;
    counter-increment: my-awesome-counter;
    @extend %flex-column;
    &:not(:last-child) {
      border-bottom: solid 1px theme-colors('primary', 'light', 0.1);
    }
    &:after {
      position: absolute;
      left: 0;
      top: 15px;
      content: counter(my-awesome-counter) '. ';
      color: theme-colors('primary', 'light', 0.8);
      font-weight: bold;
      @extend %flexbox;
      @extend %item-center;
    }
    &:before {
      position: absolute;
      content: '';
      top: 40px;
      left: 0.2rem;
      width: 2px;
      height: calc(100% - 56px);
      background-color: theme-colors('primary', 'light', 0.1);
    }
    label {
      flex: 0 0 auto;
      margin: 0;
    }
  }
}
.blu-list-submit {
  margin-top: 1.5rem;
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-end;
}
.mission-headers {
  margin-bottom: 1.5rem;
  @extend %flexbox;
  @extend %item-center;
  &__tagline {
    max-width: 60%;
    padding-right: 1rem;
    @extend %flex-column;
    h3 {
      color: theme-colors('primary', 'base', 0.6);
      margin: 0;
    }
    p {
      margin: 0;
      color: theme-colors('theam', 'dark');
    }
  }
  &__controls {
    margin-left: auto;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-end;
    li {
      flex: 0 0 auto;
      @extend %flex-column;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      label {
        flex: 0 0 auto;
        margin: 0;
      }
      span {
        flex: 0 0 auto;
      }
    }
  }
}

.model-content-scroll {
  max-height: calc(100vh - 300px);
}
.add-button {
  flex: 0 0 auto;
  font-size: 0.834rem;
  color: theme-colors('theam', 'dark');
  @extend %radius-sm;
  @extend %flexbox;
  @extend %item-center;
  &[disabled] {
    cursor: not-allowed !important;
    color: theme-colors('primary', 'base', 0.4) !important;
    span {
      padding: 0;
      &:first-child {
        padding: 0;
        margin-right: 0.5rem;
        svg {
          width: 100%;
          path {
            fill: theme-colors('primary', 'base', 0.3);
          }
        }
      }
    }
  }
  span {
    flex: 0 0 auto;
    line-height: 18px;
    &:first-child {
      margin-right: 4px;
      max-width: 16px;
      min-width: 16px;
      max-height: 16px;
      min-height: 16px;
      @extend %flexbox;
      @extend %all-center;
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('theam', 'dark');
        }
      }
    }
    &:last-child {
      font-weight: 500;
      font-size: 13px;
    }
  }
}
.edit-button {
  flex: 0 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  @extend %flexbox;
  @extend %all-center;
  svg {
    width: 70%;
    height: 70%;
    @include transition(all 0.4s ease-in-out);
    path {
      fill: theme-colors('theam', 'dark');
      @include transition(all 0.4s ease-in-out);
    }
  }

  &:hover {
    margin: 0;
    span {
      flex: 1 1 auto;
      @include transition(all 0.4s ease-in-out);
      svg {
        @include transition(all 0.4s ease-in-out);
        path {
          fill: theme-colors('primary', 'base', 0.9);
          @include transition(all 0.4s ease-in-out);
        }
      }
    }
  }
  &.hypothesis-edit {
    top: 30px;
    right: 30px;
  }
  &.notes-edit {
    cursor: pointer;
    top: 30px;
    right: 60px;
  }
  &.notes-delete {
    cursor: pointer;
    top: 30px;
    right: 30px;
  }
}

.table-action {
  @extend %flexbox;
  &__button {
    max-width: 20px;
    min-width: 20px;
    max-height: 20px;
    min-height: 20px;
    margin-right: 10px;
    @extend %flexbox;
    svg {
      width: 70%;
      height: 70%;
      @include transition(all 0.4s ease-in-out);

      path {
        fill: theme-colors('theam', 'dark');
        @include transition(all 0.4s ease-in-out);
      }
    }

    &:hover {
      span {
        flex: 1 1 auto;
        @include transition(all 0.4s ease-in-out);
        svg {
          @include transition(all 0.4s ease-in-out);

          path {
            fill: theme-colors('primary', 'base', 0.9);
            @include transition(all 0.4s ease-in-out);
          }
        }
      }
    }
  }
}

.card-dropdown {
  padding: 1rem;
  cursor: default;
  min-width: 8rem;
  max-width: 8rem;
  @extend %flex-column;
  li {
    flex: 1 1 auto !important;
    margin: 0 !important;
    color: theme-colors('primary', 'base', 0.9);
    padding: 0.35rem 0;
    cursor: pointer;
    @extend %flexbox;
    @extend %item-center;
    @include transition(all 0.4s ease-in-out);
    span {
      flex: 0 0 auto;
      @extend %flexbox;
      @extend %all-center;
      @include transition(all 0.4s ease-in-out);
      &:first-child {
        margin-right: 0.4rem;
        min-width: 0.75rem;
        max-width: 0.75rem;
        min-height: 0.75rem;
        max-height: 0.75rem;
      }
      svg {
        width: 100%;
        height: 100%;
        @include transition(all 0.4s ease-in-out);
        path {
          fill: theme-colors('primary', 'base', 0.9);
          @include transition(all 0.4s ease-in-out);
        }
      }
    }
    &:hover {
      color: theme-colors('theam', 'dark');
      @include transition(all 0.2s ease-in-out);
      svg {
        @include transition(all 0.2s ease-in-out);
        path {
          fill: theme-colors('theam', 'dark');
          @include transition(all 0.2s ease-in-out);
        }
      }
    }
  }
}
.welcome-card {
  padding: 3rem;
  h3 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
  }
}

.Constraints {
  .hoverIcon {
    display: inline-block;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    color: white;
    background-color: #969696;
    &::after {
      content: "i";
      color: white;
      display: flex;
      font-size: 10px;
      justify-content: center;
      align-items: center;
    }
  }
}

.ant-popover-inner-content {
  width: 300px !important;
}

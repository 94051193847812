@import '../../styles/abstracts/controls';
.searchBox {
  position: relative;
  min-width: 40%;
  @extend %flex-column;
  .searchBtn {
    position: absolute;
    left: 20px;
    top: 50%;
    width: 20px;
    height: 20px;
    @include transform(translateY(-50%));
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: theme-colors('primary', 'base');
      }
    }
  }
  .clearBtn {
    position: absolute;
    right: 20px;
    top: 50%;
    width: 20px;
    height: 20px;
    @include transform(translateY(-50%));
    @extend %flexbox;
    @extend %all-center;
    svg {
      width: 50%;
      height: 50%;
      path {
        fill: theme-colors('primary', 'base');
      }
    }
  }
}

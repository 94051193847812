.upcomingcard {
    border: 1px solid #4DA4DA !important;
    color: white;
    border: none;
    border-radius: 12px;
    width: 90%;
    margin: 10px auto;
    padding: 5px;
    h5,
    p {
        color: #080061;
    }
}

@import '../../../styles/abstracts/controls';
.editorWrapper {
  overflow: hidden;
  background-color: theme-colors('background', 'white');
  border: solid 1px theme-colors('primary', 'light', 0.06);
  @extend %flex-column;
  @include box-shadow(
    0px 0px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px 1px rgba(0, 0, 0, 0.01)
  );
  
  .editorToolbar_input{
    flex: 1 1 auto;
    overflow: hidden;
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    transition: all 0.8s ease-in-out;
  }
  .editorToolbar {
    flex: 0 1 auto;
    padding: 1rem;
    border-bottom: 2px solid theme-colors('primary', 'light', 0.1);
    @extend %flexbox;
    @extend %item-center;
    &__item {
      @extend %flexbox;
      @extend %all-center;
      flex: 0 0 auto;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      a {
        overflow: hidden;
        @extend %radius-sm;
        min-width: 30px;
        max-width: 30px;
        min-height: 30px;
        max-height: 30px;
        @extend %flexbox;
        @extend %all-center;
        @include transition(all 0.8s ease-in-out);
        span {
          flex: 1 1 auto;
          overflow: hidden;
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
          @extend %flexbox;
          @extend %all-center;
          @include transition(all 0.8s ease-in-out);
          svg {
            width: 80%;
            height: 80%;
            @include transition(all 0.8s ease-in-out);
            path {
              fill: theme-colors('primary', 'light', 0.9);
              @include transition(all 0.8s ease-in-out);
            }
          }
        }
        &:hover {
          background-color: theme-colors('primary', 'light', 0.1);
          @include transition(all 0.4s ease-in-out);
          span {
            @include transition(all 0.4s ease-in-out);
            svg {
              @include transition(all 0.4s ease-in-out);
              path {
                fill: theme-colors('primary', 'light', 0.8);
                @include transition(all 0.4s ease-in-out);
              }
            }
          }
        }
      }
      &:nth-child(1),
      &:nth-child(2) {
        padding: 0;
        a {
          margin: 0;
          span {
            flex: 1 1 auto;
            overflow: hidden;
            min-width: 18px;
            max-width: 18px;
            min-height: 18px;
            max-height: 18px;
            svg {
              width: 20px;
              height: 16px;
            }
          }
        }
      }
      &:nth-child(3) {
        padding: 0;
        a {
          margin: 0;
          span {
            flex: 1 1 auto;
            overflow: hidden;
            min-width: 18px;
            max-width: 18px;
            min-height: 18px;
            max-height: 18px;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .editorBody {
    flex: 1 1 auto;
    padding: 1rem;
    @extend %flex-column;
    .editorBox {
      margin: 0;
      min-height: 80px !important;

      h1 {
        margin-bottom: 1rem;
      }
      h2 {
        margin-bottom: 1rem;
      }
      p {
        line-height: 1.5;
      }
      .bulletList {
        margin: 0;
        @extend %flex-column;
        li {
          flex: 1 1 auto;
          padding-left: 1rem;
          position: relative;
          @extend %flex-column;
          &:not(:last-child) {
            margin-bottom: 0.25rem;
          }
          &:before {
            position: absolute;
            left: 0.25rem;
            content: '';
            top: 50%;
            width: 5px;
            height: 5px;
            background-color: theme-colors('primary', 'light', 0.8);
            @include border-radius(50%);
          }
        }
      }
      .numberedList {
        counter-reset: my-awesome-counter;
        @extend %flex-column;
        li {
          flex: 1 1 auto;
          padding-left: 1rem;
          position: relative;
          counter-increment: my-awesome-counter;
          @extend %flex-column;
          &:not(:last-child) {
            margin-bottom: 0.25rem;
          }
          &:before {
            position: absolute;
            left: 0.25rem;
            content: counter(my-awesome-counter) '. ';
            color: theme-colors('primary', 'light', 0.8);
          }
        }
      }
    }
  }
  .editorFooter {
    border-top: solid 1px theme-colors('primary', 'light', 0.1);
    padding: 1rem;
    flex: 0 1 auto;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-end;
  }
  .active {
    background-color: theme-colors('primary', 'light', 0.3);
    @include transition(all 0.4s ease-in-out);

    span {
      @include transition(all 0.4s ease-in-out);

      svg {
        @include transition(all 0.4s ease-in-out);

        path {
          fill: theme-colors('primary', 'light', 0.8);
          @include transition(all 0.4s ease-in-out);
        }
      }
    }
  }
}

.secondarydrivers {
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  &-card {
    margin-top: 5px;
    width: 90%;
    padding: 10px;
    border-radius: 8px;
    background-color: #8fc9f6;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-percentage {
      background-color: white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
    }
  }
  .bottom-5 {
    bottom: 5px;
  }
}

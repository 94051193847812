@import '../../styles/abstracts/controls';
.dropdown {
  flex: 0 0 auto;
  position: relative;
  @extend %flex-column;
  @include transition(all 0.4s ease-in-out);
  a {
    flex: 1 1 auto;
    @extend %flexbox;
    @extend %all-center;
  }
  .dropdown-menu {
    overflow: hidden;
    position: absolute;
    right: 0;
    opacity: 0;
    top: 100%;
    z-index: 999;
    background-color: theme-colors('background', 'white');
    border: solid 1px theme-colors('primary', 'light', 0.06);
    @include transition(all 0.4s ease-in-out);
    @include transform(scale(0) translateY(0));
    @include transform-origin(right 0);
    @include border-radius(0 0 0.25rem 0.25rem);
    @include box-shadow(
      0px 00.25rem 1rem theme-colors('primary', 'dark', 0.18)
    );
    @extend %flex-column;
    &__list {
      @extend %flex-column;
      .hvs-list-item {
        flex: 1 1 auto;
        margin: 0 !important;
        padding: 0 !important;
        a {
          flex: 1 1 auto;
          padding: 0.6rem 1rem;
          @extend %flexbox;
          @extend %item-center;
          span {
            flex: 0 0 auto;
            &:nth-child(1) {
              width: 22px;
              height: 22px;
              margin-right: 5px;
              @extend %flexbox;
              @extend %all-center;
            }
            &:nth-child(2) {
              font-size: 13px;
            }
          }
          &:hover {
            background-color: theme-colors('primary', 'base', 0.06);
          }
        }
      }
    }
  }
  &.collapse {
    margin: 0;
    @include transition(all 0.3s ease-in-out);
    .dropdown-menu {
      opacity: 1;
      @include transform(scale(1) translateY(20px));
      @include transition(all 0.4s ease-in-out);
    }
  }
}

.meetingspreparing {
  border-radius: 15px;
  margin: auto;
  .add-button-container {
    text-align: center;
    padding: 5px;
    border: 1px blue solid;
    border-radius: 5px;
    color: blue;
    margin-top: 20px;
    cursor: pointer;
  }
  .bg-gray-prep {
    background-color: #ebebeb;
    padding: 10px;
  }
  .text-default-blue {
    color: blue;
  }
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    background-color: #fff;
    border-bottom: 1px #4da3d9 solid;
    margin-top: 30px;
  }
  .colapse-style {
    background-color: #fff !important;
    border-bottom: 1px #3c5dd6 solid;
  }
  .goals-container {
    height: 44vh;
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
  }
  .ant-modal-header {
    background: #ecf7ff !important;
  }

  .border-error {
    border-color: red !important;
  }

  .ant-modal-title {
    color: #000 !important;
  }

  .ant-modal-body {
    padding: 0 24px;
  }

  .ant-picker {
    padding: 0;
  }
  .input-class,
  .team-members,
  .select-class,
  .select-class-multiple {
    border: 1px solid #ecf7ff;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    background-color: #fff;
  }

  .select-class-multiple {
    height: auto;
  }

  .datePicker {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    padding: 5px;
  }

  .vertical {
    justify-content: center;
    align-items: center;
  }

  .vertical-center {
    align-items: baseline;
  }

  form {
    position: relative;
    label {
      font-size: 15px;
    }

    .select-class {
      padding: 0;
    }
    button {
      cursor: pointer;
      padding: 8px;
      background: #3c5dd6;
      color: #fff;
      border: none;
      width: 100px;
      height: auto;
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
      transition: 0.5s;
      margin: 15px 10px;
    }
    button:hover {
      background: #152250;
      transition: 0.5s;
    }
    .form-columns {
      display: grid;
    }
    .mt-20 {
      margin-top: 20px;
    }

    .team-members {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border: none;
      box-shadow: none;
      width: auto;
      margin-left: 15px;
    }

    .plusIconVertical,
    .plusIconHorizontal,
    .closeIconLeft,
    .closeIconRight {
      cursor: pointer;
      position: absolute;
      top: 40%;
      width: 5px;
      height: 20px;
      background-color: rgb(207, 207, 207);
      transform: rotate(0) translate(-50%, -50%);
      transform-origin: top left;
      content: "";
      transition: all 0.2s ease-in;
    }
    .plusIconHorizontal {
      transform: rotate(-90deg) translate(-50%, -50%);
    }

    .closeIconLeft {
      transform: rotate(45deg) translate(-50%, -50%);
    }
    .closeIconRight {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
    .modalMembersVisible,
    .modalMembersHidden {
      position: absolute;
      background-color: #fff;
      border-radius: 15px;
      transition: all 0.2s ease-in;
      right: 0;
      opacity: 1;
      border: 1px rgb(207, 207, 207) solid;
      width: 350px;
      transition: all 0.5s;
      padding: 10px;
      z-index: 999999999;
      height: 250px;
      overflow-y: auto;
    }
    .modalMembersHidden {
      width: 0;
      opacity: 0;
      transition: all 0.2s ease-in;
    }
    .close-icon {
      color: #000;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      border: #000 solid 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      cursor: pointer;
    }
    .align-baseline {
      align-items: baseline;
    }
    .container-button {
      text-align: end;
    }
  }
}

.dashboardlayout {
  .sidebar {
    position: fixed;
    background: white;
    border-radius: 12px;
    padding: 50px;
    height: 100vh;
    .item {
      font-size: 18px;
      margin-bottom: 18px;
      color: black;
    }
  }
  .bg-white {
    background-color: white;
  }
}

@import '../../styles/abstracts/controls';

.card {
  flex: 1 1 auto;
  height: 100%;
  position: relative;
  z-index: 0;
  margin-bottom: 1.5rem;
  overflow: hidden;
  background-color: theme-colors('background', 'white');
  border: solid 1px theme-colors('primary', 'light', 0.06);
  @extend %flex-column;
  @extend %radius-xl;
  @include box-shadow(
    0px 0px 8px rgba(0, 0, 0, 0.05),
    0px 0px 1px 1px rgba(0, 0, 0, 0.01)
  );
  .cardHeader {
    flex: 0 1 auto;
    padding: 1rem 1rem 0 1rem;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
    h3 {
      margin-bottom: 0;
      color: theme-colors('primary', 'base', 0.6);
    }
  }
  .cardCover {
    position: relative;
    flex: 1 1 auto;

    img {
      cursor: pointer;
    }
  }
  .cardBody {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    @extend %flex-column;

    h3 {
      cursor: pointer;
    }
  }
  .cardFooter {
    position: relative;
    border-top: solid 1px theme-colors('primary', 'light', 0.1);
    flex: 0 1 auto;
    padding: 1rem;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-end;
  }
}

@import './mixins';
%inline-flex {
  @include inline-flex;
}
%flexbox {
  @include flexbox();
  @include flex-direction(row);
}
%flex-column {
  @include flexbox();
  @include flex-direction(column);
}
%row-reverse {
  @include flex-direction(row-reverse);
}
%column-reverse {
  @include flex-direction(column-reverse);
}
%all-center {
  @include justify-content(center);
  @include align-items(center);
}

%justify-center {
  @include justify-content(center);
}

%justify-between {
  @include justify-content(space-between);
}

%justify-around {
  @include justify-content(space-around);
}

%justify-start {
  @include justify-content(flex-start);
}

%justify-end {
  @include justify-content(flex-end);
}

%item-center {
  @include align-items(center);
}

%item-start {
  @include align-items(flex-start);
}

%item-end {
  @include align-items(flex-end);
}

%item-stretch {
  @include align-items(stretch);
}

%item-nowrap {
  @include flex-wrap(nowrap);
}

%item-wrap {
  @include flex-wrap(wrap);
}

%image-overlay {
  @include linear-gradient(
    to top,
    (
      theme-colors('primary', 'base', $opacity: 0.7) 20%,
      theme-colors('primary', 'base', $opacity: 0.01) 100%
    )
  );
}
%timeline-box {
  @include linear-gradient(
    to top,
    (
      theme-colors('primary', 'base', $opacity: 0.1) 20%,
      theme-colors('primary', 'base', $opacity: 0.01) 100%
    )
  );
}
%break-text {
  word-break: break-word;
  line-break: strict;
  white-space: normal;
  @include hyphens('auto');
}
%radius-xs {
  @include border-radius(0.125rem);
}
%radius-sm {
  @include border-radius(0.25rem);
}
%radius-md {
  @include border-radius(0.375rem);
}
%radius-lg {
  @include border-radius(0.5rem);
}
%radius-xl {
  @include border-radius(0.625rem);
}
%radius-xxl {
  @include border-radius(0.75rem);
}
%radius-round {
  @include border-radius(50%);
}
%bg-gray {
  background-color: theme-colors('theam', 'light', 0.05);
}
%very-gradient {
  @include linear-gradient(
    to bottom,
    (theme-colors('theam', 'base') 30%, theme-colors('theam', 'light') 100%)
  );
}
%progressbar-bg {
  @include linear-gradient(
    to right,
    (theme-colors('theam', 'dark') 30%, theme-colors('theam', 'light') 100%)
  );
}

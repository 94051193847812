.modal {
    &-title {
        background-color: #102431;
        width: 100%;
    }
    &-image-header {
        width: 92px;
        margin: 13px 25px;
    }
    &-form {
        padding: 100px;
        padding-top: 50px;
        &-button {
            margin-top: 17px;
            background-color: #3d5dd6 !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 2px;
            width: 100%;
        }
        &-input {
            margin-top: 20px;
        }
        &-text {
            font-size: 12px;
        }
        @media (max-width: 599px) {
            padding: 10px;
        }
    }
    &-close-icon {
        color: #fff;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        border: #fff solid 1px;
    }
}
.ant-modal-close {
    background-color: transparent !important;
}

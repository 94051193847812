@import '../../styles/abstracts/controls';
.searchContainer {
  margin-bottom: 1.5rem;
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-between;
  @include media('<phone') {
    @include flex-direction(column);
  }
  @include media('>=phone', '<576px') {
    @include flex-direction(column);
  }
  @include media('>=576px', '<tablet') {
    @include flex-direction(column);
  }
  &__input {
    flex: 0 0 auto;
    margin-right: 1rem;
    @extend %flexbox;
    @extend %item-center;
    @include media('<phone') {
      margin-right: 0;
      @include flex-direction(column);
    }
    @include media('>=phone', '<576px') {
      margin-right: 0;
      @include flex-direction(column);
    }
    @include media('>=576px', '<tablet') {
      margin-right: 0;
      @include flex-direction(column);
    }
    .itemSearch {
      flex: 1 1 auto;
      min-width: 400px;
    }
    .resultCount {
      margin-left: 1rem;
      @extend %flexbox;
      @extend %item-center;
      @include media('<phone') {
        margin-left: 0;
        margin-top: 1rem;
      }
      @include media('>=phone', '<576px') {
        margin-left: 0;
        margin-top: 1rem;
      }
      @include media('>=576px', '<tablet') {
        margin-left: 0;
        margin-top: 1rem;
      }
      span {
        flex: 0 0 auto;
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6) {
          font-weight: 700;
        }
      }
    }
  }
  &__controls {
    flex: 0 0 auto;
    align-items: center;
    @include media('<phone') {
      margin-top: 1rem;
    }
    @include media('>=phone', '<576px') {
      margin-top: 1rem;
    }
    @include media('>=576px', '<tablet') {
      margin-top: 1rem;
    }
  }
}

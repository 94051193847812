@font-face {
  font-family: RedHat;
  src: url(../../Assets/redhat.ttf);
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.RedHat{font-family: RedHat;}

.DarkDullBlueBackground{
  background-color: #102431;
}

.LightBlueForeground{
  color:#3E98C7;
}

.WhiteForeground{
  color: #fff;
}

.DullWhiteForeground{
  color: #CDD9EC;
}

.DateFilterField{
  border: 1px solid lightgray;
  background: #fff;
  color: #09171F;
  font-size: 12px;
  padding: 5px;
  
}

.BlackForeground{
  color: #09171F;
}

.GreyForeground{
  color: #adb1b4;
}

.Navbar{
  width: 100vw ;
  height: 60px;
  padding-left: 90px;
    padding-right: 90px;
}

.NavbarButtons{
  width: max-content;
  margin-right: 10px;
  margin-left: 10px;
  display: inline;
}

.SidebarButtons{
  cursor: pointer;
  width: inherit;
  padding: 12px;
}

.SidebarOverflow{
  overflow-y: auto;
  height: 70vh;
}


.MarginRight10{
  margin-right: 10px;
}

.AlignRight{
  text-align: right;
}

.AlignCenter{
  text-align: center;
}

.AlignLeft{
  text-align: left;
}

.FloatRight{
  float: right;
}

.FloatLeft{
  float: left;
}

.InlineDisplay{
  display: inline;
}

.FontSize16{
  font-size: 16px;
}

.FontSize18{
  font-size: 18px;
}

.FontSize20{
  font-size: 20px;
}

.FontSize14{
  font-size: 14px;
}

.Bold{
  font-weight: 600;
}

.PaddingLeftRight{
  padding: 20px 100px 20px 100px ;
}

.BorderBottom{
  border: none;
  border-bottom: 1px solid rgb(236, 233, 233);
}

.Sidebarbox{
  height: fit-content;
  margin-bottom: 10px;
  width: inherit;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-image: linear-gradient(to right,#CE5829, #FCC258);
}

.Sidebarboxgrey{
  height: fit-content;
  margin-bottom: 10px;
  width: inherit;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color:#CDD9EC;
}

.BottomNavbar{
  width: 100vw;
  border-top: 1px solid rgb(236, 233, 233);
  padding: 20px 90px 20px 90px;
}

.DashboardHome{
  padding-left: 50px!important;
}

.AssessmentHome{
  padding-left: 50px!important;
  padding-top: 50px!important;
}

.DashboardHomeDiv{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: inherit;
  height: max-content;
  padding: 15px;
}

.AllTestDiv{
  background-image: linear-gradient(to right,#1A2553, #4DA4DA) ;
  border-radius: 5px;
  width: inherit;
  height: max-content;
  padding: 15px 20px 15px 20px;
}

.TakeAssessmentDiv{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: inherit;
  height: max-content;
}

.TakeAssessmentDiv-header{
  border-radius: 5px 5px 0px 0px;
  width: inherit;
  height: max-content;
  padding:15px;
}
.TakeAssessmentDiv-maindiv{
  width: inherit;
  height: max-content;
  padding:20px;
}

.TakeAssessmentDiv-tag{
  border-radius: 15px;
  width: max-content;
  height: max-content;
  padding:5px 15px 5px 15px;
  background: #717C84;
}

.FontSize12{
  font-size: 12px;
}

.hr1{
  border-top: 2px solid #50A3DA !important;
}

.ColoredDashboardDiv{
  cursor: pointer;
  width: 100%;
  border-radius: 7px;
  padding: 10px 20px 10px 20px;
  margin: 0px;
}

.modalscreen {
  display: none; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0, 0, 0); 
  background-color: rgba(0, 0, 0, 0.7); 
}

.modal-content-screen {
  background-color: #FFFFFF;
  margin-left: auto; 
  margin-right: auto;
  padding: 10px;
  border: 1px solid #BFBFBF;
  border-radius: 5px;
  width: 30%;
  margin-top: 250px; 
}


.SVGSize{
  height: 15px;
  
}

.SVGSize30{
  height: 30px;
  
}

.SVGFill-blue{
  fill: #50A3DA;
}

.SVGFill-grey{
  fill: grey;
}

.SVGFill-white{
  fill: white
}

.Sidebarbackgroundicon{
  width: 40px;
  padding: 15px 15px 20px 15px;
  background-color: #50A3DA;
  border-radius: 5px;
}

.red, .orange, .yellow,.yellow::-webkit-progress-value{
  background-image: linear-gradient(to right,#CE5829, #FCC258);
}

.test{
  background-image: linear-gradient(to right,#CE5829, #FCC258);
}

.blue, .dark_blue, .dark_blue::-webkit-progress-value{
  background-image: linear-gradient(to right,#1A2553, #4DA4DA) 
}

.light_blue, .light_blue::-webkit-progress-value{
  background-image: linear-gradient(to right,#3888b6, #9fcfec) 
}

.pink, .pink::-webkit-progress-value{
  background-image: linear-gradient(to right,#FEB683, #FF8993);  
}

.dashboarddivgradient1, .purple, .purple::-webkit-progress-value{
  background-image: linear-gradient(to right, #495159, #1A2A33);
}

.dashboarddivgradient2{
  background-image: linear-gradient(to right, #3E98C7, #377CA3);
}

.dashboarddivgradient4, .green, .green::-webkit-progress-value{
  background-image: linear-gradient(to right, #1F9A40, #89C937);
}

.plaingreen{
  background-color: #1F9A40;
}

.plainblue{
  background-color: #3E98C7;
}

.plainorange{
  background-color: #CE5829;
}

.GreyDashboardDiv{
  border: 1px solid #B5B5B5;
  width: 100%;
  border-radius: 7px;
  padding: 10px 20px 10px 20px;
  margin: 0px;
}

.dashboarddivgradient3{
  background-image: linear-gradient(to bottom, #F8F8F8, #E8E8E8);
}

.NewButton{
  width: max-content;
  padding:  5px 15px 5px 15px;
  font-size: 14px;
  outline: none;
  border: none;
  background-color: #285775;
  border-radius: 5px;
  color: #fff;
}

.NewAssessmentDiv{
  border-radius: 10px;
  border: 1px solid lightgray;
  width: 100%;
  text-align: center;
  padding: 30px;
}

.ProgressBarDiv{
  border-radius: 10px;
  border: 1px solid lightgray;
  width: 100%;
  padding:  30px  30px  30px 60px;
}

.MCQDiv{
  width: 100%;
  height: 100%;
  border: 1px solid #B6B7B7;
  border-radius: 5px;
  background-color: #F1F1F1;
  padding: 10px;
}

.RedButton{
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #CE5829;
  color: #fff;
  width: max-content;
  padding:  5px 18px 5px 18px;
  font-size: 14px;
  border-radius: 5px;
}

.GreenButton{
  outline: none;
  border: none;
  background-color: #00B206;
  color: #fff;
  width: max-content;
  padding:  5px 18px 5px 18px;
  font-size: 14px;
  border-radius: 5px;
}

.FinishAssessmentDiv1{
  border-radius: 10px;
  padding:  10px 20px 10px 20px;
  width: 100%;
}

.WhiteCircleDiv{
  height: 70px;
  width: 70px;
  border-radius: 60px;
  background-color: white;
  padding: 10px;
}

.WhiteCircleToolboxDiv{
  height: 30px;
  width: 30px;
  border-radius: 60px;
  background-color: white;
  padding: 2px;
}

.CongratulationsImg{
  content: url(../../Assets/congratulations.png);
}

.AvatarImg{
  content: url(../../Assets/avatar.png);
  height: 20px;
}

.CompletedImg{
  content: url(../../Assets/Completed.png);
  height: 20px;
}

.OngoingImg{
  content: url(../../Assets/Ongoing.png);
  height: 20px;
}

.LessonLearnedImg{
  content: url(../../Assets/Lessons-Learned.png);
  
}

.FatCardIcon{
  background-color: white;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  padding: 10px;
  margin-top: 15px;
}

.ImgWhiteBackground{
  width: 15px;
  padding: 2px;
  border-radius: 2px;
  background-color: white;
}

.longcard{
  width: 100%;
  border: 1px solid rgb(202, 201, 201);
  border-radius: 5px;
  padding: 10px;
  margin-top: 15px;
  display: flex;
}

.similarmission{
  padding: 30px 100px 30px 100px;
}

.similarmissioncard{
  padding:25px 30px 15px 30px;
  border-radius: 10px;
  text-align: center;
  color: white
}

.similarmissioncard-button{
  width: 100%;
  text-align: center;
  border-radius: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: black;
  border: none;
  outline: none;
  margin-top: 10px;
}

.Fatcard{
  width: 100%;
  border: 1px solid rgb(202, 201, 201);
  border-radius: 5px;
  padding: 5px 20px 10px 20px;
  margin-top: 15px;
  color: white;
  text-align: left;
}

.longcardchild{
  width: fit-content;
  padding: 15px;
  border: 1px solid rgb(202, 201, 201);
  border-radius: 5px;
  margin-left: 5px;
  flex: none;
  color: white;
}

.greycircle{
  width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: rgb(187 187 187);
    opacity: 0.5;
    text-align: center;
    margin-left: 9px;
    color: black;
}


.PlusImg{
  content: url(../../Assets/plus.png);
  height: 20px;
}

.NegativeImg{
  content: url(../../Assets/negative.png);
  height: 20px;
}

.CommunicatorImg{
  content: url(../../Assets/communicator.png);
  height: 20px;
}

.PrimarycareImg{
  content: url(../../Assets/primarycare);
  width: 30px;
}

.SpecialitycareImg{
  content: url(../../Assets/specialitycare);
  width: 30px;
}

.ToolboxImg{
  content: url(../../Assets/toolbox.png);
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius:50%
}

.LogoWhiteImg{
  content: url(../../Assets/blueagilis-whitelogo.png);
  width: 70px;
}

.EmptystateImg{
  content: url(../../Assets/emptystate.png);
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.Flex{
  display: flex!important;
}

.MiniDiv{
  border-left: 1px solid lightgrey;
  margin-left: 50px;
}

.ProgressBar {
  border-radius: 7px; 
  width: 100%;
  height: 20px;
}
.ProgressBar::-webkit-progress-bar {
  background-color: lightgrey;
  border-radius: 15px;
}
.ProgressBar::-webkit-progress-value {
  background-color: blue;
  border-radius: 15px;
}


.Pointer{
  cursor: pointer;
}

.AssessmentScoreDiv{
  border-radius: 10px;
  width: 19.8%;
  padding: 15px;
  display: inline-block;
  background-color: black;
  color: white;
  font-size: 12px;
  height: 100%;
  vertical-align: bottom;
}

.ScoreButton{
  border: none;
  background-color: #FFFFFF;
  color: black;
  border-radius: 15px;
  outline: none;
  padding:  5px 10px 5px 10px;
  font-size: 12px;
}

.p_2{
  padding: 2%;
}
.mx_2{
  margin-left: 3%;
}
.scoreboard_card{
  height: 400px;
  border-radius:10px;
  color: white;
}
.scoreboard_card__header{
  padding: 10% 10%;

}
.scoreboard_card_textarea{
  width: 100%;
  border-radius: 5px;
  margin-top:10px
}
.scoreboard_card_submit{
  width: 100%;
  display: flex;
  justify-content: flex-end;

}
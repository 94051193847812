@import '../../styles/abstracts/controls';

.asideBacklink {
  padding: 1rem;
  background-color: theme-colors('theam', 'dark', 0.1);
  @extend %flex-column;

  a {
    @extend %flexbox;

    span {
      flex: 0 0 auto;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:first-child {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: theme-colors('primary', 'base', 0.6);
          }
        }
      }

      &:last-child {
        flex: 1 1 auto;

        h4 {
          font-size: 1rem;
          margin: 0;
        }

        p {
          font-size: 13px;
          margin: 0;
          color: theme-colors('primary', 'base', 0.4);
        }
      }
    }
  }
}
.disabled {
  padding: 0 !important;
  margin-top: 5px;
  a {
    @extend %flexbox;
    background-color: theme-colors('theam', 'dark', 0.4);
    padding: 0.3rem;
    border-radius: 20px !important;

    .asideBacklinkorange_text {
      flex: 7;
      width: 50%;

      h4 {
        font-size: 1rem;
        color: #d6c7c7 !important;
        // color: #fff;
        margin: 0;
      }

      p {
        font-size: 13px;
        margin: 0;
        color: #d6c7c7 !important;
      }
    }
    .ToolboxImg {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      flex: 1;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: theme-colors('primary', 'base', 0.6);
        }
      }
    }

    .lock_icon {
      color: rgb(58, 58, 58) !important;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.asideBacklinkorange {
  padding: 1rem;
  background-image: linear-gradient(to right, #ce5829, #fcc258);
  @extend %flex-column;

  a {
    @extend %flexbox;

    span {
      flex: 0 0 auto;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:first-child {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: theme-colors('primary', 'base', 0.6);
          }
        }
      }

      &:last-child {
        flex: 1 1 auto;

        h4 {
          font-size: 1rem;
          color: #fff;
          margin: 0;
        }

        p {
          font-size: 13px;
          margin: 0;
          color: #fff;
          // color: theme-colors('primary', 'base', 0.4);
        }
      }
    }
  }
}

.organisationInsights {
  @extend %flex-column;
  .insightsHeader {
    margin-bottom: 2rem;
  }
  .insightsContent {
    .insightsAside {
      margin-top: 15px;
      flex: 1 1 auto;
      &__header {
        padding-bottom: 1rem;
        border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
        @extend %flex-column;
        h3 {
          margin-top: 0;
          line-height: 2.5rem;
          margin-bottom: 0;
          color: theme-colors('primary', 'light', 0.8);
        }
        h4 {
          margin-top: 0;
          margin-bottom: 0;
          color: theme-colors('primary', 'base', 0.6);
        }
      }
      &__elements {
        flex: 0 1 auto;
        padding-bottom: 1rem;
        margin-top: 1rem;
        border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
        @extend %flexbox;
        @extend %item-center;
        li {
          flex: 0 0 auto;
          @extend %flexbox;
          @extend %item-center;
          &:not(:last-child) {
            margin-right: 1rem;
          }
          span {
            flex: 0 0 auto;
            line-height: 24px;
            @extend %flexbox;
            @extend %all-center;
            &:first-child {
              margin-right: 0.75rem;
              min-width: 24px;
              max-width: 24px;
              min-height: 24px;
              max-height: 24px;
              svg {
                width: 100%;
                height: 100%;
                path {
                  fill: theme-colors('primary', 'base', 0.6);
                }
              }
            }
            &:last-child {
              font-weight: 600;
              min-height: 24px;
              max-height: 24px;
              padding: 0 0.75rem;
              border: 1px solid theme-colors('primary', 'base', 0.1);
              color: theme-colors('theam', 'dark');
              @extend %radius-sm;
            }
          }
        }
      }
      &__list {
        flex: 1 1 auto;
        margin: 0;
        @extend %flex-column;
        .disabledd {
          padding: 2px;
          border-radius: 20px !important;
          background-color: rgb(177, 177, 177) !important;

          a {
            @extend %flexbox;
            @extend %item-center;
            // background-color: theme-colors('theam', 'dark', 0.4);

            // background-color: rgb(188, 181, 181) !important;
            flex: 1 1 auto;
            color: theme-colors('primary', 'base', 0.8);
            padding: 0.5rem 0.5rem !important;
            span {
              width: 100%;
              flex: 0 0 auto;
              @extend %break-text;
              &:not(:last-child) {
                margin-right: 1rem;
              }
              .float_right {
                float: right !important;
                margin-right: 3rem;
              }
              &:first-child {
                min-width: 20px;
                max-width: 20px;
                min-height: 20px;
                max-height: 20px;
                svg {
                  width: 100%;
                  height: 100%;
                  path {
                    fill: theme-colors('primary', 'base', 0.6);
                  }
                }
              }
            }
            &:hover,
            &.current {
              color: theme-colors('theam', 'dark');
              @include transition(all 0.4s ease-in-out);
              span {
                flex: 0 0 auto;
                &:first-child {
                  padding: 0;
                  svg {
                    width: 100%;
                    path {
                      fill: theme-colors('theam', 'dark') !important;
                    }
                  }
                }
              }
            }
          }
        }

        li {
          flex: 1 1 auto;
          padding: 1rem;

          font-size: 0.875rem;
          cursor: pointer;
          color: theme-colors('primary', 'base', 0.7);
          @include transition(all 0.4s ease-in-out);
          @extend %flex-column;
          &:not(:last-child) {
            border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
            margin-bottom: 2px;
          }
          a {
            flex: 1 1 auto;
            color: theme-colors('primary', 'base', 0.8);
            @extend %flexbox;
            @extend %item-center;
            span {
              flex: 0 0 auto;
              @extend %break-text;
              &:not(:last-child) {
                margin-right: 1rem;
              }
              &:first-child {
                min-width: 20px;
                max-width: 20px;
                min-height: 20px;
                max-height: 20px;
                svg {
                  width: 100%;
                  height: 100%;
                  path {
                    fill: theme-colors('primary', 'base', 0.6);
                  }
                }
              }
            }
            &:hover,
            &.current {
              color: theme-colors('theam', 'dark');
              @include transition(all 0.4s ease-in-out);
              span {
                flex: 0 0 auto;
                &:first-child {
                  padding: 0;
                  svg {
                    width: 100%;
                    path {
                      fill: theme-colors('theam', 'dark') !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .insightsBody {
      padding-left: 3.5rem;
      @extend %flex-column;
      .notesCreated {
        margin-bottom: 8px;
        @extend %flexbox;
        @extend %item-center;
        label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
          color: theme-colors('primary', 'base', 0.5);
        }
        span {
          color: theme-colors('theam', 'dark');
          font-weight: 500;
          background-color: theme-colors('primary', 'base', 0.03);
          @extend %radius-sm;
        }
      }
    }
  }
}

.bg__orange {
  background-image: linear-gradient(to right, #ce5829, #fcc258);
}
.active_color{
  background-image: linear-gradient(to right, #2968ce, #225ab3);
}
// .disabled{
//   padding: 2px;
//   border-radius: 20px !important;
//   background-color: theme-colors('theam', 'dark', 0.4);

// }

.bg__orange a span {
  color: white;
}

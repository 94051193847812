@import '../../styles/abstracts/controls';

.mainHeader {
  flex: 0 1 auto;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  padding: 0 15px;
  background-color: theme-colors('primary', 'base');
  @include box-shadow(0px 2px 3px theme-colors('theam', 'base', 0.3));
  @extend %flex-column;

  .headerInner {
    height: 4.265rem;
    @extend %flexbox;
    @extend %item-center;

    &__brand {
      max-width: 120px;
      @extend %flexbox;
      @extend %all-center;
      span {
        margin: 0;
        max-width: 80px;
        color: theme-colors('background', 'white');
      }
    }
    &__navigation {
      margin: 0;
      margin-left: auto;
      flex: 0 0 auto;
      @extend %flexbox;
      @extend %item-center;
      li {
        flex: 1 1 auto;
        &:not(:last-child) {
          margin-right: 2rem;
        }
        a {
          color: theme-colors('background', 'white');
          @extend %flexbox;
          @extend %item-center;
          @include transition(all 0.8s ease-in-out);
          display: flex;
          align-items: center;
          gap: 0.5rem;
         
          span {
            flex: 0 0 auto;
            font-size: 1rem;
            font-weight: 500;
            color: theme-colors('background', 'white');
            @include transition(all 0.8s ease-in-out);
            &:first-child {
              background-color: theme-colors('theam', 'dark', 0.6);
              padding: 4px;
              margin-right: 0.5rem;
              max-width: 2rem;
              min-width: 2rem;
              max-height: 2rem;
              min-height: 2rem;
              @extend %radius-sm;
              @extend %flexbox;
              @extend %all-center;
              svg {
                width: 100%;
                height: 100%;
                @include transition(all 0.8s ease-in-out);
                path {
                  fill: theme-colors('background', 'white');
                  @include transition(all 0.8s ease-in-out);
                }
              }
            }
          }
          &:hover {
            margin: 0;
            span {
              color: theme-colors('background', 'white');
              @include transition(all 0.4s ease-in-out);
              svg {
                @include transition(all 0.4s ease-in-out);
                path {
                  fill: theme-colors('background', 'white');
                  @include transition(all 0.4s ease-in-out);
                }
              }
            }
          }
          &.profileLink {
            @extend %flexbox;
            @extend %all-center;
            .showProfile {
              @extend %flexbox;
              @extend %all-center;
              padding: 0;
              overflow: hidden;
              max-width: 2rem;
              min-width: 2rem;
              max-height: 2rem;
              min-height: 2rem;
              img {
                @extend %radius-sm;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../styles/abstracts/controls";

.dashboard {
  margin: 0;
  min-height: calc(100vh - 6rem);
  .dashboardBanner {
    position: relative;
    background-image: url(../../../public/img/platform-banner.jpg);
    background-position: center;
    background-size: cover;
    height: 300px;
    background-repeat: no-repeat;
    @extend %flex-column;

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      z-index: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .bannerBlock {
      position: relative;
      z-index: 4;
      padding: 4rem 0;
      flex: 1 1 auto;
      @extend %flex-column;
      @extend %all-center;

      h2 {
        text-align: center;
        margin: 0 0 0.25rem 0;
        color: theme-colors("background", "white");
      }

      p {
        text-align: center;
        font-size: 1rem;
        color: theme-colors("theam", "dark");
      }
    }
  }
  .dashboardInner {
    @extend %flex-column;
    @extend %justify-center;
    .tabs_main_div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .grid_mode_main {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        .grid_tabs {
          width: 35px;
          padding: 5px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .active {
          background-color: #4da4da;
        }
      }
    }
    .dashboardItem {
      padding: 0;
      .addOrganization {
        height: 100%;
        padding: 3rem 0;
        cursor: pointer;
        @extend %flex-column;
        @extend %all-center;
        &__btn {
          min-width: 60px;
          max-width: 60px;
          min-height: 60px;
          max-height: 60px;
          background-color: theme-colors("background", "green");
          color: theme-colors("background", "white");
          @extend %radius-sm;
          @extend %flex-column;
          @extend %all-center;

          span {
            flex: 1 1 auto;
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
            color: theme-colors("background", "white");

            svg {
              width: 100%;
              height: 100%;

              path {
                fill: theme-colors("background", "white");
              }
            }
          }
        }

        h3 {
          margin-bottom: 0;
          margin-top: 1rem;
          color: theme-colors("primary", "light", 0.8);
        }
      }

      .blueOrganization {
        padding: 1rem;
        cursor: pointer;
        position: relative;
        height: 100%;
        @extend %flex-column;

        &__controls {
          position: absolute;
          top: 1rem;
          right: 1rem;
          @extend %flexbox;
          @extend %all-center;
        }

        &__brand {
          flex: 0 1 auto;
          margin-bottom: 1rem;
          @extend %flexbox;
          @extend %all-center;
          @include media("<phone") {
            margin-bottom: 1rem;
          }
          @include media(">=phone", "<tablet") {
            margin-bottom: 1rem;
          }
          @include media(">=tablet", "<=992px") {
            margin-bottom: 1rem;
          }
          .brandLogo {
            flex: 0 0 auto;
            min-width: 80px;
            max-width: 80px;
            min-height: 80px;
            max-height: 80px;
            overflow: hidden;
            background-color: theme-colors("background", "white");
            @extend %radius-sm;
            @extend %flexbox;
            @extend %all-center;
            @include box-shadow(0px 1px 6px theme-colors("primary", "dark", 0.06));
          }

          span {
            flex: 1 1 auto;
            min-width: 70px;
            max-width: 70px;
            min-height: 70px;
            max-height: 70px;
            overflow: hidden;
            background-color: theme-colors("primary", "light", 0.1);
            color: theme-colors("background", "white");
            @extend %radius-sm;
            @extend %flexbox;
            @extend %all-center;

            img {
              min-height: 70px;
              @extend %radius-sm;
            }
          }
        }

        &__details {
          flex: 1 1 auto;
          @include media("<phone") {
            padding: 0;
          }
          @include media(">=phone", "<tablet") {
            padding: 0;
          }
          @include media(">=tablet", "<=992px") {
            padding: 0;
          }

          h3 {
            font-size: 1.25rem;
            line-height: 1.75rem;
            text-align: center;
            cursor: pointer;
            color: theme-colors("primary", "light", 0.8);
            @include media("<phone") {
              text-align: center;
            }
            @include media(">=phone", "<tablet") {
              text-align: center;
            }
            @include media(">=tablet", "<=992px") {
              text-align: center;
            }
          }

          .updateDate {
            margin-top: 0.25rem;
            @extend %flexbox;
            @extend %all-center;

            span {
              flex: 0 0 auto;

              &:not(:last-child) {
                margin-right: 1rem;
              }

              &:nth-child(2) {
                font-weight: 600;
                font-size: 13px;
                color: theme-colors("theam", "dark");
              }
            }
          }

          .missionsCount {
            margin-top: 1rem;
            @extend %flexbox;
            @extend %all-center;

            li {
              flex: 0 0 auto;
              @extend %flex-column;
              @extend %all-center;

              &:not(:last-child) {
                margin-right: 1rem;
              }

              span {
                flex: 0 0 auto;
                text-align: center;
                @extend %flexbox;
                @extend %all-center;

                &:first-child {
                  font-weight: 600;
                  color: theme-colors("theam", "dark");
                }

                &:nth-child(2) {
                  color: theme-colors("primary", "light", 0.6);
                }
              }
            }
          }
        }
      }
    }

    .dropBox {
      min-height: 1.2rem;
      max-height: 1.2rem;
      min-width: 1.2rem;
      max-width: 1.2rem;
      cursor: pointer;
      @extend %flexbox;
      @extend %all-center;

      .dropIcon {
        min-width: 0.25rem;
        max-width: 0.25rem;
        @extend %flexbox;
        @extend %all-center;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: theme-colors("primary", "light", 0.6);
          }
        }
      }
    }
    .network_tabel {
      cursor: pointer;
    }
  }

  .layoutMeetings {
    background-color: #d9d9d9;
    border-radius: 15px;
    padding: 25px
  }
}
.organisationUpload {
  @extend %flex-column;
  &__loader {
    margin-top: 8px;
    @extend %flexbox;
    @extend %item-center;
    span {
      flex: 0 0 auto;
      &:last-child {
        padding-left: 0.75rem;
      }
    }
  }
  &__hints {
    @extend %flex-column;
    padding: 0.75rem;
    background-color: theme-colors("background", "warning", 0.2);
    border-left: 4px solid theme-colors("background", "warning", 0.8);
  }
}
.orgGroup {
  font-weight: 600;
  font-size: 13px;
  color: white;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: blue;
}

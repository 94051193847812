.messenger {
  &-card {
    position: relative;
    background-color: white;
    border-radius: 15px;
    height: 300px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(12, 35, 120, 0.25);
    margin-top: 15px;
    &-option {
      width: 90%;
      margin: auto;
    }
  }
}

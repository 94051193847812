div.form-table-container {
  div.ant-table-content {
    table {
      tr {
        td {
          span.anticon {
            +span.anticon {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  div.form-table-container-editor {
    div.header {
      display: flex;
      align-items: center;
      border-bottom: 2px solid #56a6d1;
    }

    div.ant-space {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div.ant-space-item {
        flex: 1;

        &:first-child,
        &:last-child {
          flex: 0;
        }

        //client role
        &:nth-child(3) {
          width: 300px;
        }

        div.ant-row {
          div.ant-col {
            &.ant-form-item-label {
              padding: 0 0 3px;
            }
          }
        }
      }
    }

    select,
    input {
      &.ant-input-sm {
        min-height: 33px;
        padding: 0 0.5rem;
      }

      &::placeholder {
        color: #cccccc;
      }
    }

    button {
      padding: 3px 15px;
    }
  }
}

div.ant-select-dropdown {
  div.ant-cascader-menus {
    width: 500px;
    display: flex;

    ul.ant-cascader-menu {
      width: 100%;
      min-width: 50%;
      flex: 1;

      li.ant-cascader-menu-item {
        >div {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  form.add-role-form {
    display: flex;
    width: 100%;

    div.ant-form-item {
      flex: 0;

      &:first-child {
        flex: 1;
      }

      input {
        &::placeholder {
          color: #cccccc;
        }
      }
    }
  }
}
@import '../../styles/abstracts/controls';
.pageLoader {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 200px);
  z-index: 999999;
  @extend %flexbox;
  @extend %all-center;
  &__inner {
    background-color: white;
    padding: 10px;
    @extend %flex-column;
    @extend %all-center;
    @include border-radius(8px);
    .loading {
      width: 150px;
      height: 2px;
      position: relative;
      overflow: hidden;
      flex: 1 1 auto;
      background-color: theme-colors('primary', 'base', 0.2);
      &:before {
        content: '';
        width: 75px;
        height: 2px;
        position: absolute;
        left: -34px;
        background: #0073b1;
        animation: loadBar 1.5s infinite ease;
        @include animation(
          'loader-inner 0.8s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite'
        );
        @include keyframes(loader-inner) {
          50% {
            left: 100px;
          }
        }
      }
    }
  }
}

.personadata {
  display: grid;
  padding: 15px;
  border: 1px solid #08ABC4;
  border-radius: 5px;
}

.ModalPersona {
  .ant-select {
    .ant-select-selector {
      min-height: auto !important;
      border: none !important;
    }
  }
}
.evaluation {
  .select-class-new {
    border: 1px solid #bac7d0;
    border-radius: 6px;
    background-color: #fff !important;
  }
  .select-class-multiple {
    border: 1px solid #bac7d0;
    border-radius: 6px;
    height: 10px;
    width: 100%;
    background-color: #fff;
  }
  .btn {
    &-edit,
    &-save,
    &-cancel {
      border: 2px solid #3c5dd6;
      border-radius: 8px;
      font-size: 14px;
      padding: 5px 10px;
      color: #fff;
      background-color: #3c5dd6;
      cursor: pointer;
    }
  }
}

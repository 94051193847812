@font-face {
  font-family: RedHat;
  src: url(../../../Assets/redhat.ttf);
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.mt-2 {
  margin-top: 0.5rem !important;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}

.mt-3 {
  margin-top: 1rem !important;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.m-0 {
  margin: 0 !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  outline: 0;
  vertical-align: baseline;
}
* {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.RedHat {
  font-family: RedHat;
}

.DarkDullBlueBackground {
  background-color: #102431;
}

.LightBlueForeground {
  color: #3e98c7;
}

.WhiteForeground {
  color: #fff;
}

.DullWhiteForeground {
  color: #cdd9ec;
}

.DateFilterField {
  border: 1px solid lightgray;
  background: #fff;
  color: #09171f;
  font-size: 12px;
  padding: 5px;
}

.BlackForeground {
  color: #09171f;
}

.GreyForeground {
  color: #adb1b4;
}

.Navbar {
  width: 100vw;
  height: 60px;
  padding-left: 90px;
  padding-right: 90px;
}

.NavbarButtons {
  width: max-content;
  margin-right: 10px;
  margin-left: 10px;
  display: inline;
}

.SidebarButtons {
  cursor: pointer;
  width: inherit;
  padding: 12px;
}

.SidebarOverflow {
  overflow-y: auto;
  height: 70vh;
}

.MarginRight10 {
  margin-right: 10px;
}

.AlignRight {
  text-align: right;
}

.AlignCenter {
  text-align: center;
}

.FloatCenter {
  margin-left: auto;
  margin-right: auto;
}

.FloatRight {
  float: right;
}

.InlineDisplay {
  display: inline;
}

.FontSize16 {
  font-size: 16px;
}

.FontSize18 {
  font-size: 18px;
}

.FontSize20 {
  font-size: 20px;
}

.FontSize14 {
  font-size: 14px;
}

.Bold {
  font-weight: 600;
}

.PaddingLeftRight {
  padding: 20px 100px 20px 100px;
}

.BorderBottom {
  border: none;
  border-bottom: 1px solid rgb(236, 233, 233);
}

.Sidebarbox {
  height: fit-content;
  margin-bottom: 10px;
  width: inherit;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ce5829, #fcc258);
}

.Sidebarboxgrey {
  height: fit-content;
  margin-bottom: 10px;
  width: inherit;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #cdd9ec;
}

.BottomNavbar {
  width: 100vw;
  border-top: 1px solid rgb(236, 233, 233);
  padding: 20px 90px 20px 90px;
}

.DashboardHome {
  padding: 50px !important;
}

.AssessmentHome {
  padding: 0px 50px !important;
  padding-top: 50px !important;
}

.DashboardHomeDiv {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: inherit;
  height: max-content;
  padding: 15px;
}

.AllTestDiv {
  background-image: linear-gradient(to right, #1a2553, #4da4da);
  border-radius: 5px;
  width: inherit;
  height: max-content;
  padding: 15px 20px 15px 20px;
}

.TakeAssessmentDiv {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: inherit;
  height: max-content;
}

.PlusImg {
  content: url(../../../Assets/plus.png);
  height: 20px;
}

.NegativeImg {
  content: url(../../../Assets/negative.png);
  height: 20px;
}

.TakeAssessmentDiv-header {
  border-radius: 5px 5px 0px 0px;
  width: inherit;
  height: max-content;
  padding: 15px;
}
.TakeAssessmentDiv-maindiv {
  width: inherit;
  height: max-content;
  padding: 20px;
}

.TakeAssessmentDiv-tag {
  border-radius: 15px;
  width: max-content;
  height: max-content;
  padding: 5px 15px 5px 15px;
  background: #717c84;
}

.FontSize12 {
  font-size: 12px;
}

.hr1 {
  border-top: 2px solid #50a3da;
}

.ColoredDashboardDiv {
  cursor: pointer;
  width: 100%;
  border-radius: 7px;
  padding: 10px 20px 10px 20px;
  margin: 0px;
}

.modalscreen {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content-screen {
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  width: 30%;
  margin-top: 250px;
}

.SVGSize {
  height: 15px;
}

.SVGSize30 {
  height: 30px;
}

.SVGFill-blue {
  fill: #50a3da;
}

.SVGFill-grey {
  fill: grey;
}

.SVGFill-white {
  fill: white;
}

.Sidebarbackgroundicon {
  width: 40px;
  padding: 15px 15px 20px 15px;
  background-color: #50a3da;
  border-radius: 5px;
}

.red,
.orange,
.yellow,
.yellow::-webkit-progress-value {
  background-image: linear-gradient(to right, #ce5829, #fcc258);
}

.test {
  background-image: linear-gradient(to right, #ce5829, #fcc258);
}

.blue,
.dark_blue,
.dark_blue::-webkit-progress-value {
  background-image: linear-gradient(to right, #1a2553, #4da4da);
  color: #ffffff;
}

.light_blue,
.light_blue::-webkit-progress-value {
  background-image: linear-gradient(to right, #3888b6, #9fcfec);
}

.pink,
.pink::-webkit-progress-value {
  background-image: linear-gradient(to right, #feb683, #ff8993);
}

.dashboarddivgradient1,
.purple,
.purple::-webkit-progress-value {
  background-image: linear-gradient(to right, #495159, #1a2a33);
  color: #ffffff;
}

.dashboarddivgradient2 {
  background-image: linear-gradient(to right, #3e98c7, #377ca3);
}

.dashboarddivgradient4,
.green,
.green::-webkit-progress-value {
  background-image: linear-gradient(to right, #1f9a40, #89c937);
}

.GreyDashboardDiv {
  border: 1px solid #b5b5b5;
  width: 100%;
  border-radius: 7px;
  height: 42px;
  padding: 8px;
  margin: 0px;
}
.DownloadImg {
  content: url(../../../Assets/download.svg);
  width: 23px;
}

.uploadingImg {
  content: url(../../../Assets/download.svg);
  width: 23px;
}
.ViewImg {
  content: url(../../../Assets/view.svg);
  width: 23px;
}

.dashboarddivgradient3,
.white {
  background-image: linear-gradient(to bottom, #f8f8f8, #e8e8e8);
}

.NewButton {
  width: max-content;
  padding: 5px 15px 5px 15px;
  font-size: 14px;
  outline: none;
  border: none;
  background-color: #285775;
  border-radius: 5px;
  color: #fff;
}

.NewAssessmentDiv {
  border-radius: 10px;
  border: 1px solid lightgray;
  width: 100%;
  text-align: center;
  padding: 30px;
}

.ProgressBarDiv {
  border-radius: 10px;
  border: 1px solid lightgray;
  width: 100%;
  padding: 30px 30px 30px 60px;
}

.MCQDiv {
  width: 100%;
  height: 100%;
  border: 1px solid #b6b7b7;
  border-radius: 5px;
  background-color: #f1f1f1;
  padding: 10px;
}

.RedButton {
  outline: none;
  border: none;
  background-color: #ce5829;
  color: #fff;
  width: max-content;
  padding: 5px 18px 5px 18px;
  font-size: 14px;
  border-radius: 5px;
}

.GreenButton {
  outline: none;
  border: none;
  background-color: #00b206;
  color: #fff;
  width: max-content;
  padding: 5px 18px 5px 18px;
  font-size: 14px;
  border-radius: 5px;
}

.FinishAssessmentDiv1 {
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  width: 100%;
}

.WhiteCircleDiv {
  height: 70px;
  width: 70px;
  border-radius: 60px;
  background-color: white;
  padding: 10px;
}

.WhiteCircleToolboxDiv {
  height: 30px;
  width: 30px;
  border-radius: 60px;
  background-color: white;
  padding: 2px;
}

.CongratulationsImg {
  content: url(../../../Assets/congratulations.png);
}

.AvatarImg {
  content: url(../../../Assets/avatar.png);
  height: 20px;
}

.ImgWhiteBackground {
  width: 15px;
  padding: 2px;
  border-radius: 2px;
  background-color: white;
}

.CommunicatorImg {
  content: url(../../../Assets/communicator.png);
  height: 20px;
}

.PrimarycareImg {
  content: url(../../../Assets/primarycare);
  width: 30px;
}

.SpecialitycareImg {
  content: url(../../../Assets/specialitycare);
  width: 30px;
}

.QualityImg {
  content: url(../../../Assets/Quality.png);
}

.PatientSafetyImg {
  content: url(../../../Assets/Patient-Safety.png);
}

.PatientExperienceImg {
  content: url(../../../Assets/Patient-Experience.png);
}

.CostOfCareImg {
  content: url(../../../Assets/Cost-of-Care.png);
}

.User01Img {
  content: url(../../../Assets/user-01.png);
}

.User02Img {
  content: url(../../../Assets/user-02.png);
}

.User03Img {
  content: url(../../../Assets/user-03.png);
}

.VisitSummariesImg {
  content: url(../../../Assets/Visit\ Summaries.svg);
}

.DemographicsImg {
  content: url(../../../Assets/Demographics.svg);
}

.ToolboxImg {
  content: url(../../../Assets/toolbox.png);
  width: 30px;
}

.LogoWhiteImg {
  content: url(../../../Assets/blueagilis-whitelogo.png);
  width: 70px;
}

.EmptystateImg {
  content: url(../../../Assets/emptystate.png);
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.Flex {
  display: flex !important;
}

.MiniDiv {
  border-left: 1px solid lightgrey;
  margin-left: 50px;
}

.ProgressBar {
  border-radius: 7px;
  width: 100%;
  height: 20px;
}
.ProgressBar::-webkit-progress-bar {
  background-color: lightgrey;
  border-radius: 15px;
}
.ProgressBar::-webkit-progress-value {
  background-color: blue;
  border-radius: 15px;
}

.Pointer {
  cursor: pointer;
}

.AssessmentScoreDiv {
  border-radius: 10px;
  width: 19.8%;
  padding: 15px;
  display: inline-block;
  background-color: black;
  color: white;
  font-size: 12px;
  height: 100%;
  vertical-align: bottom;
}

.AssessmentScoreDiv-margin {
  margin-right: 4px;
}

.ScoreButton {
  border: none;
  background-color: #ffffff;
  color: black;
  border-radius: 15px;
  outline: none;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  margin-bottom: 15px;
}

.PercentageCard {
  border: solid 4px transparent;
  cursor: pointer;
}

.PercentageCardDetail .border-left {
  border-left:solid 2px #30DEF7;
}

.icon-inverse {
  transform: rotate(180deg);
}

.meetingsreflections {
  background-color: white;
  border-radius: 15px;
  padding: 25px;
  margin: auto;
  .close-icon-file {
    color: #3C5DD6;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: #3C5DD6 solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  form {
    button {
      cursor: pointer;
      padding: 8px;
      background: #3c5dd6;
      color: #fff;
      border-radius: 8px;
      border: none;
      width: 100px;
      height: auto;
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
      transition: 0.5s;
      margin: 15px 0; 
    }
    .container-button {
      text-align: end;
    }
  }
  .emoji {
    width: 80px;
    height: 80px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }

  .emoji-selected {
    width: 80px;
    height: 80px;
    transform: scale(1.2);
  }
  .ml-5 {
    margin-left: 5px;
  }

  .select-class {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;
    padding: 5px;
    background-color: #fff !important;
    padding: 0%;
    font-size: 25px;
  }

  .input-class {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    width: auto;
    padding: 5px;
    background-color: #fff;
  }

  .font-18 {
    font-size: 18px;
  }
}

.contactus {
  .w-40px {
    width: 40px;
    height: 38px;
  }
  .textarea {
    border-radius: 10px;
    margin-top: 10px;
    padding: 5px;
    width: 100%;
    border: 1px solid #d0d0d0;
    resize: none;
    height: 100px;
  }

  .save-btn-contact {
    cursor: pointer;
    border-radius: 8px;
    background-color: #0478c6;
    border: 1px solid #0478c6;
    color: #fff;
    padding: 5px;
  }

  .save-btn-contact {
    &:hover {
      background-color: #2b4399;
    }
  }
}

@import '../../styles/abstracts/controls';
.mainFooter {
  flex: 0 1 auto;
  width: 100%;
  z-index: 100;
  border-top: solid 1px theme-colors('primary', 'base', 0.07);
  @extend %flex-column;
  .footersInner {
    padding: 1.5rem 0;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
    @include media('<phone') {
      margin-right: 0;
      @include flex-direction(column);
    }
    @include media('>=phone', '<576px') {
      margin-right: 0;
      @include flex-direction(column);
    }
    @include media('>=576px', '<tablet') {
      margin-right: 0;
      @include flex-direction(column);
    }
    &__list {
      flex: 0 0 auto;
      @extend %flexbox;
      @extend %item-center;
      li {
        flex: 0 0 auto;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
    &__right {
      flex: 0 0 auto;
      @extend %flexbox;
      @extend %item-center;
      li {
        flex: 0 0 auto;
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }
  }
}

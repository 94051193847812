@import '../../styles/abstracts/controls';

.profileBlock {
  padding: 1rem;
  background-color: theme-colors('primary', 'light', 0.03);
  @extend %flex-column;
  @extend %all-center;
  &__image {
    overflow: hidden;
    position: relative;
    max-width: 100px;
    min-width: 100px;
    max-height: 100px;
    min-height: 100px;
    @extend %radius-sm;
    span {
      flex: 1 1 auto;
      padding: 1px !important;
      max-width: 100px !important;
      min-width: 100px !important;
      max-height: 100px !important;
      min-height: 100px !important;
      @extend %flexbox;
      @extend %all-center;
      img {
        @extend %radius-sm;
      }
    }
    .editOverlay {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
      background-color: theme-colors('primary', 'dark', 0.5);
      @extend %flexbox;
      @extend %all-center;
      @include transition(all 0.8s ease-in-out);
    }
    &:hover {
      margin: 0;
      .editOverlay {
        height: 70%;
        opacity: 1;
        @include transition(all 0.4s ease-in-out);
      }
    }
  }

  &__name {
    flex: 1 1 auto;
    padding-top: 10px;
    margin: 0;
    font-size: 16px;
    color: theme-colors('primary', 'dark');
  }
  &__designation {
    margin-top: 4px;
    padding: 0 10px;
    border: theme-colors('theam', 'light') solid 1px;
    color: theme-colors('theam', 'light') !important;
    background-color: theme-colors('background', 'white');
    @include border-radius(50px);
  }
}
.userProfile {
  min-width: 280px;
  background-color: theme-colors('background', 'white');
  @extend %flex-column;
  li {
    margin-right: 0 !important;
    padding: 0.75rem 2rem;
    @extend %flexbox;
    @extend %item-center;
    a {
      flex: 0 0 auto;
      color: theme-colors('primary', 'dark') !important;
      @extend %flexbox;
      @extend %item-center;
      span {
        flex: 0 0 auto;
        font-size: 0.846rem !important;
        font-weight: 400 !important;
        padding: 0 !important;
        background-color: transparent !important;
        color: theme-colors('primary', 'dark') !important;
        line-height: 20px;
        &:first-child {
          margin-right: 6px !important;
          max-width: 1rem !important;
          min-width: 1rem !important;
          max-height: 1rem !important;
          min-height: 1rem !important;
          svg {
            width: 100%;
            height: 100%;
            path {
              fill: theme-colors('primary', 'dark') !important;
            }
          }
        }
      }
      &:hover {
        color: theme-colors('theam', 'dark') !important;
        @include transition(all 0.4s ease-in-out);
        span {
          color: theme-colors('theam', 'dark') !important;
          @include transition(all 0.4s ease-in-out);
          &:first-child {
            @include transition(all 0.4s ease-in-out);
            svg {
              width: 90%;
              height: 90%;
              path {
                fill: theme-colors('theam', 'dark') !important;
                @include transition(all 0.4s ease-in-out);
              }
            }
          }
        }
      }
      &.logoutBtn {
        border: 0;
        flex: 1 1 auto;
        padding: 4px 10px;
        min-height: 22px;
        color: theme-colors('background', 'white') !important;
        background-color: theme-colors('background', 'error', 0.6);
        @extend %radius-sm;
        span {
          color: theme-colors('background', 'white') !important;
        }
        &:hover {
          background-color: theme-colors('background', 'error');
          @include transition(all 0.4s ease-in-out);
          span {
            color: theme-colors('background', 'white') !important;
            &:first-child {
              @include transition(all 0.4s ease-in-out);
              svg {
                width: 100%;
                height: 100%;
                path {
                  fill: theme-colors('background', 'white') !important;
                }
              }
            }
          }
        }
        span {
          flex: 0 0 auto;
          line-height: 20px;
          color: theme-colors('background', 'white');
          &:first-child {
            margin-right: 6px;
            max-width: 1rem !important;
            min-width: 1rem !important;
            max-height: 1rem !important;
            min-height: 1rem !important;
            svg {
              width: 100%;
              height: 100%;
              path {
                fill: theme-colors('background', 'white') !important;
              }
            }
          }
        }
      }
    }
    &.logoutPane {
      border-top: theme-colors('primary', 'dark', 0.06) solid 1px;
    }
  }
}
.logoutConformity {
  padding: 2rem;
  max-width: 500px;
  @extend %flex-column;
  @extend %all-center;
  h4 {
    text-align: center;
    margin-bottom: 0.5rem;
    color: theme-colors('primary', 'base', 0.8);
  }
  p {
    text-align: center;
    color: theme-colors('primary', 'base', 0.6);
  }
  &__btn {
    margin-top: 1rem;
    @extend %flexbox;
    @extend %all-center;
  }
}

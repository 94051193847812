@import '../../styles/abstracts/controls';

.login-wrapper {
  min-height: 100vh;
  background-image: url('../../Assets/blurred_color_background.svg');
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @extend %flex-column;
  @extend %all-center;
  &:before,
  &:after {
    position: absolute;
    content: '';
  }
  &:before {
    width: 120px;
    bottom: 0;
    left: 20px;
    overflow: hidden;
    height: 200px;
    background-image: url(../../../public/img/login-tree-01.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &:after {
    width: 100px;
    bottom: 0;
    right: 5%;
    overflow: hidden;
    height: 170px;
    background-image: url(../../../public/img/login-tree-02.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .login-block {
    width: 100%;
    max-width: 60%;
    height: 100vh;
    @extend %flexbox;
    @include media('<phone') {
      max-width: 100%;
      @include flex-direction(column);
    }
    @include media('>=phone', '<tablet') {
      max-width: 100%;
      @include flex-direction(column);
    }
    @include media('>=tablet', '<=992px') {
      max-width: 100%;
      @include flex-direction(column);
    }
    @include media('>=992px', '<=desktop') {
      max-width: 80%;
      margin: 0;
    }
    &__theam {
      flex: 1 1 50%;
      min-width: 50%;
      max-width: 50%;
      position: relative;
      @extend %flex-column;
      @include media('<phone') {
        flex: 1 1 auto;
        min-width: 100%;
        max-width: 100%;
      }
      @include media('>=phone', '<tablet') {
        flex: 1 1 auto;
        min-width: 100%;
        max-width: 100%;
      }
      @include media('>=tablet', '<=992px') {
        flex: 1 1 auto;
        min-width: 100%;
        max-width: 100%;
      }
      .theam-content {
        position: absolute;
        top: 32%;
        z-index: 2;
        width: 100%;
        max-width: 60%;
        min-width: 60%;

        .login-graphics {
          position: relative;
          @extend %flexbox;
          @extend %justify-end;
          span {
            flex: 0 0 auto;
            max-width: 200px;
          }
        }
        h2 {
          font-size: 4.25rem;
          margin-bottom: 1.5rem;
          line-height: 1;
          font-weight: 500;
          color: theme-colors('primary', 'light', 0.7);
          @extend %flex-column;
          @include media('<phone') {
            font-size: 1.25rem;
          }
          @include media('>=phone', '<tablet') {
            font-size: 2.25rem;
          }
          @include media('>=tablet', '<=992px') {
            font-size: 2.5rem;
          }
          span {
            flex: 1 1 auto;
          }
        }
        p {
          line-height: 22px;
          margin: 0;
          color: theme-colors('primary', 'base', 0.6);
        }
        .signup-link {
          flex: 1 1 auto;
          padding-top: 12px;
          @extend %flexbox;
          @extend %item-center;
          a {
            flex: 0 0 auto;
            margin-left: 8px;
          }
        }
      }
    }
    &__forms {
      flex: 1 1 50%;
      min-width: 50%;
      max-width: 50%;
      @extend %flex-column;
      @extend %all-center;
      @include media('<phone') {
        flex: 1 1 auto;
        min-width: 100%;
        max-width: 100%;
      }
      @include media('>=phone', '<tablet') {
        flex: 1 1 auto;
        min-width: 100%;
        max-width: 100%;
      }
      @include media('>=tablet', '<=992px') {
        flex: 1 1 auto;
        min-width: 100%;
        max-width: 100%;
      }
      .login-item {
        flex: 0 0 auto;
        width: 100%;
        max-width: 60%;
        min-width: 60%;
        @include media('<phone') {
          min-width: 100%;
          max-width: 100%;
        }
        @include media('>=phone', '<tablet') {
          min-width: 100%;
          max-width: 100%;
        }
        @include media('>=tablet', '<=992px') {
          min-width: 100%;
          max-width: 100%;
        }
        .form-pane {
          @extend %flex-column;
          &__list {
            @extend %flex-column;
            @include transition(all 0.8s ease-in-out);
            li {
              flex: 1 1 auto;
              @include transition(all 0.2s ease-in-out);
              &:not(:last-child) {
                margin-bottom: 1.2rem;
              }
              .signup-split {
                margin: 0 -8px;
                flex: 1 1 auto;
                @extend %flexbox;
                @extend %item-start;
                &__item {
                  flex: 1 1 50%;
                  margin: 0 8px;
                }
              }
            }
          }
        }
        .forgot-password {
          position: relative;
          padding-top: 4.5rem;
          @extend %flex-column;
          @extend %all-center;

          &:before,
          &:after {
            position: absolute;
            content: '';
            width: calc(50% - 80px);
            height: 1px;
            background-color: theme-colors('primary', 'base', 0.1);
          }

          &:before {
            left: 0;
          }

          &:after {
            right: 0;
          }

          a {
            flex: 0 0 auto;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}

.password-wrapper {
  @extend %flex-column;
  .password-graphics {
    flex: 1 1 auto;
    padding-bottom: 10px;
    @extend %flex-column;
    @extend %all-center;
    span {
      max-width: 200px;
      max-width: 50%;
    }
  }
  h3 {
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    margin-bottom: 2rem;
  }
  .password-list {
    @extend %flex-column;
    li {
      flex: 1 1 auto;
      text-align: center;
      @extend %flex-column;
      @extend %all-center;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &:last-child {
        @extend %flex-column;
        @extend %all-center;
      }
    }
  }
}
.verify-email {
  height: 100vh;
  @extend %flex-column;
  @extend %all-center;
  &__content {
    @extend %flex-column;
    @extend %all-center;
    &--logo {
      max-width: 120px;
      @extend %flexbox;
      @extend %all-center;
      span {
        margin: 0;
        max-width: 80px;
        color: theme-colors('background', 'white');
      }
    }
    h3 {
      margin-bottom: 1rem;
      color: theme-colors('primary', 'base', 0.8);
    }
    p {
      padding-bottom: 0.5rem;
      color: theme-colors('primary', 'base', 0.6);
      a {
        color: theme-colors('theam', 'light');
        @include transition(all 0.8s ease-in-out);
        &:hover {
          color: theme-colors('theam', 'dark');
          @include transition(all 0.2s ease-in-out);
        }
      }
    }
  }
}

@import "styles/base/reset";
@import "styles/base/typography";
@import "styles/vendors/common";
@import "styles/vendors/antd";
@import "utils/animate";

* {
  margin: 0;
  padding: 0;
}

header ul img {
  width: auto;
}

.ant-input-disabled {
  color: black !important;
}

::selection {
  color: theme-colors("background", "white") !important;
  background-color: theme-colors("theam", "light");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @include box-shadow(0 0 1000px theme-colors("background", "white") inset);
  background-color: theme-colors("background", "white") !important;
}

input:-internal-autofill-selected {
  @include box-shadow(0 0 1000px theme-colors("background", "white") inset);
  background-color: theme-colors("background", "white") !important;
  -webkit-text-fill-color: theme-colors("primary", "dark");
}

button,
input,
textarea,
select {
  @include box-shadow(0, 0, 0, theme-colors("background", "white") !important);

  &:hover,
  &:visited,
  &:focus,
  &:active {
    @include box-shadow(0, 0, 0, theme-colors("background", "white") !important);
    outline: 0;
  }
}

button {
  &.small {
    padding: 2px 5px;
  }

  &.primary {
    background-color: #3c5dd6;
    color: #ffffff;
    border: none;
    padding: 3px 5px;
    border-radius: 5px;
    cursor: pointer;
  }
}

input[type="checkbox"],
label[for] {
  cursor: pointer;
}

div.ant-modal-content {
  div.ant-modal-confirm-body-wrapper {
    div.ant-modal-confirm-btns {
      display: flex;
      justify-content: center;
    }
  }
}

img {
  border: 0;
  outline: 0;
  max-width: 100%;
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: $font-head;
  color: theme-colors("primary", "dark");
  @extend %break-text;
}

a {
  text-decoration: none;
  color: theme-colors("theam", "dark");

  [disabled] {
    color: theme-colors("primary", "base", 0.3);
    cursor: not-allowed;
    pointer-events: all;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: theme-colors("theam", "light");
  }
}

h1 {
  font-size: 2.25rem;
  line-height: 3.125rem;
  font-weight: 600;
}

h2 {
  font-size: 1.625rem;
  line-height: 2.125rem;
  font-weight: 500;
}

h3 {
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: 500;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 500;
}

h5 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
}

p {
  margin: 0;
  padding: 0;
  color: theme-colors("primary", "base", 0.6);
  @extend %break-text;
}

#root,
body,
html {
  height: 100%;
  scroll-behavior: smooth;
}

body > iframe {
  display: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font-head;
  color: theme-colors("primary", "base");
  background-color: theme-colors("background", "white");
  background-color: #ecf7ff;

  &.disable-scroll {
    overflow: hidden !important;
  }
}

.flex {
  display: flex !important;

  &.justify-between {
    justify-content: space-between;
  }

  &.spaced {
    > * {
      align-items: start;
      justify-content: center;
      display: inline-flex;
      flex-direction: column;

      + * {
        margin-left: 10px;
      }
    }
  }

  .flex-cover {
    flex-grow: 1;
  }

  .flex-2-4,
  .flex-1-2 {
    flex-basis: 50%;
  }

  .flex-1-3 {
    flex-basis: 33.3333%;
  }

  .flex-1-4 {
    flex-basis: 25%;
  }

  .flex-1-5 {
    flex-basis: 20%;
  }

  .flex-2-3 {
    flex-basis: 66.6666%;
  }

  .flex-3-4 {
    flex-basis: 75%;
  }

  .flex-2-5 {
    flex-basis: 40%;
  }

  .flex-3-5 {
    flex-basis: 60%;
  }

  .flex-4-5 {
    flex-basis: 80%;
  }

  .flex-2-2,
  .flex-3-3,
  .flex-4-4,
  .flex-5-5 {
    flex-basis: 100%;
  }
}

.grid {
  display: grid;
}

.grid-column-1 {
  grid-column: 1;
}

.m-auto {
  margin: auto;
}

.mx-15 {
  margin: 0 15px;
}

.mt-5 {
  margin-top: 5px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.m-auto {
  margin: auto;
}

.center-flex-align {
  display: flex;
  justify-content: center;
}

.pt-2rem {
  padding-top: 2rem;
}

.pt-15 {
  padding-top: 15px !important;
}

.pr-2rem {
  padding-right: 2rem;
}

.layoutSection {
  background-color: #ecf7ff;
  border-radius: 15px;
  padding: 25px;
  margin: auto;
}

.px-25 {
  padding: 0 25px;
}

.px-10 {
  padding: 0 10px;
}

.px-15 {
  padding: 0 15px;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end !important;
}

.justify-beetween {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: end;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mt-2rem {
  margin-top: 2rem;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-25 {
  margin-bottom: 25px;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  text-align: center;
}

.mx-25 {
  margin: 0 25px;
}

.mx-75 {
  margin: 0 75px;
}

.pointer {
  cursor: pointer !important;
}

.cursor-block {
  cursor: not-allowed !important;
}

.my-15 {
  margin: 15px auto;
}

.my-25 {
  margin: 25px auto;
}

.my-5 {
  margin: 5px auto;
}

.my-10 {
  margin: 10px auto;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-50 {
  margin-top: 50px;
}

.border-error {
  border-color: red !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-left {
  justify-content: flex-start;
}

.hidden {
  display: none !important;
}

.textarea {
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  border: 1px solid #d0d0d0;
  resize: none;
  height: 200px;
}

.h-3rem {
  height: 3rem;
}

.h-250px {
  height: 250px;
}

.h-300px {
  height: 300px !important;
}

.h-80px {
  height: 80px;
}

.h-75vh {
  height: 75vh;
}

.h-auto {
  height: auto;
}

.block {
  display: block !important;
}

.bg-white {
  background-color: white !important;
}

.bg-red {
  background-color: red !important;
}

.filter-white {
  filter: brightness(0) invert(1);
}

.w-650 {
  width: 650px;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-20 {
  width: 20%;
}

.w-23 {
  width: 23%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.pl-15 {
  padding-left: 15px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-50 {
  padding-right: 50px;
}

.-top-10 {
  top: -10px;
}

.bg-transparent {
  background-color: transparent;
}

.border-0 {
  border: 0;
}

.action-btn-atom {
  cursor: pointer;
  background: #3c5dd6;
  color: #fff;
  border: none;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
  margin-left: 15px;
  padding: 10px;
}

.border-r-50 {
  border-radius: 50px;
}

.border-r-15 {
  border-radius: 15px;
}

.border-r-10 {
  border-radius: 10px;
}

.border-r-5 {
  border-radius: 5px;
}

.negative-margin {
  margin: 0 -15px;
}

.font-17 {
  font-size: 17px;
}

.ml-auto {
  margin-left: auto;
}

.text-white {
  color: #fff;
}

.p-8 {
  padding: 8px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.px-25 {
  padding: 0 25px;
}

.p-5 {
  padding: 5px;
}

.font-18 {
  font-size: 18px;
}

.gap-10 {
  gap: 10px;
}

.text-15 {
  font-size: 15px;
}

.text-black {
  color: #000;
}

.text-blue {
  color: #4da4da !important;
}

.opacity-5 {
  opacity: 0.5;
}

.text-20 {
  font-size: 20px;
}

.h-35 {
  height: 35% !important;
}

.h-50 {
  height: 50% !important;
}

.h-70 {
  height: 70% !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

iframe {
  body {
    overflow: hidden;
  }
}

.h-auto {
  height: auto !important;
}

.flex-1 {
  flex: 1;
}

.pointer-events-none {
  pointer-events: none;
}

.datePicker {
  border: 1px solid #d9d9d9;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 40px;
  padding: 5px;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #b7e0ff;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  transform: translateY(-1px) !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  background-color: #e3e3f4;
  border-radius: 12px 12px 0px 0px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #4da3d9 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.ant-tabs-tab:hover {
  background-color: #4da3d9 !important;
  color: #fff;
}

.select-class {
  border: 1px solid #ecf7ff;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 40px;
  width: 300px;
  background-color: #fff !important;
  margin-bottom: 15px !important;
}

.hr-color {
  border-top: 1px solid #4da3d9;
}

.bg-blue-900 {
  background-color: #111e62;
}

.ant-collapse-content {
  background-color: transparent !important;
}

.ant-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  background-color: #4da3d9;
}

.input-class {
  border: 1px solid #bac7d0;
  border-radius: 6px;
  padding: 5px;
  box-shadow: none;
  background-color: #fff;

  &:disabled {
    background-color: #e3e3f4;
    cursor: not-allowed;
  }
}

.borderArrayIcon {
  border: 1px solid;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.grid-vertical {
  place-items: center;
}

.word-break {
  word-break: break-all;
}

.text-red {
  color: red;
}

.fixed {
  position: fixed;
}

.transition-1-linear {
  transition: all 1s linear;
}

.transition-1-ease {
  transition: all 1s ease;
}

.opacity-0 {
  opacity: 0;
}

.btm-0 {
  bottom: 0 !important;
}

.d-none {
  display: none !important;
}

.pt-50 {
  padding-top: 50px;
}

.pt-22 {
  padding-top: 22px;
}

.pt-25 {
  padding-top: 25px;
}

.arrow-back {
  width: 10px;
  height: 10px;
}

.bg-gray-prep {
  background-color: #ebebeb;
  padding: 10px;
}

.add-button-container-g {
  text-align: center;
  padding: 5px;
  border: 1px blue solid;
  border-radius: 5px;
  color: blue;
  margin-top: 20px;
  cursor: pointer;
}

.text-default-blue-g {
  color: blue;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-base {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-xl {
  font-size: 20px;
}

.text-xxl {
  font-size: 25px;
}

.overflow-h {
  overflow: hidden !important;
}

.b-b-white {
  border-bottom: 1px solid white;
}

.bottom-0 {
  bottom: 0;
}

.b-rounded-5 {
  border-radius: 5px;
}

.b-rounded-10 {
  border-radius: 10px;
}

.email-dropdown {
  .ant-select-dropdown {
    display: none !important;
  }
}

.ant-select {
  &.ant-select-disabled {
    background-color: #e3e3f4 !important;
  }
}

.text-left {
  text-align: left;
}

.close-icon {
  color: #000;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: #000 solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.flex-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
}

.min-w-auto {
  min-width: auto !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.bg-yellow {
  background-color: #faad14;
}

.border-rounder {
  border-radius: 50%;
}

.g-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.b-color-gray {
  border: 1px solid #b0b0b0;
}

.warning-color {
  background-color: #faad14;
}

.text-orange {
  color: #ce5829;
}

.hr-white {
  border-top: 1px solid white !important;
}
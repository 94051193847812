@import '../abstracts/controls';
.verticalList {
  margin: 1rem 0;
  @extend %flex-column;
  li {
    flex: 1 1 auto;
    padding-left: 1rem;
    position: relative;
    @extend %flex-column;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
.bulletedList {
  margin: 1rem 0;
  @extend %flex-column;
  li {
    flex: 1 1 auto;
    padding-left: 1rem;
    position: relative;
    @extend %flex-column;
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
    &:before {
      position: absolute;
      left: 0.25rem;
      content: '';
      top: 50%;
      width: 5px;
      height: 5px;
      background-color: theme-colors('primary', 'light', 0.8);
      @include border-radius(50%);
    }
  }
}
.numberedList {
  margin: 1rem 0;
  counter-reset: my-awesome-counter;
  @extend %flex-column;
  li {
    flex: 1 1 auto;
    padding-left: 1rem;
    position: relative;
    counter-increment: my-awesome-counter;
    @extend %flex-column;
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
    &:before {
      position: absolute;
      left: 0.25rem;
      content: counter(my-awesome-counter) '. ';
      color: theme-colors('primary', 'light', 0.8);
    }
  }
}
.messageWarning {
  flex: 0 0 auto;
  margin-top: 2rem;
  padding: 1rem;
  background-color: theme-colors('background', 'warning', 0.2);
  @extend %flexbox;
  @extend %item-center;
  @extend %radius-sm;
  @include transition(all 0.8s ease-in-out);
  span {
    flex: 1 1 auto;
    font-size: 1rem;
    color: theme-colors('background', 'warning');
    &:first-child {
      flex: 0 0 auto;
      margin-right: 10px;
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-width: 30px;
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('background', 'warning', 0.8);
        }
      }
    }
  }
}
.archived {
  opacity: 0.5;
  background-color: theme-colors('primary', 'light', 0.04);
}
.cardControls {
  position: absolute;
  z-index: 9;
  right: 1rem;
  @extend %flexbox;
  @extend %all-center;
  .controlsItem {
    @extend %flexbox;
    @extend %all-center;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    .favoriteLine,
    .activeFavoriteLine,
    .dropBox {
      min-height: 1.2rem;
      max-height: 1.2rem;
      min-width: 1.2rem;
      max-width: 1.2rem;
      cursor: pointer;
      @extend %flexbox;
      @extend %all-center;

      .dropIcon {
        min-width: 0.25rem;
        max-width: 0.25rem;
        @extend %flexbox;
        @extend %all-center;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: theme-colors('primary', 'light', 0.6);
          }
        }
      }
    }
    .favoriteLine {
      flex: 1 1 auto;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: theme-colors('primary', 'light', 0.6);
          stroke-width: 6px;
          fill: none;
        }
      }
    }
    .activeFavoriteLine {
      margin: 0;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: none;
          fill: theme-colors('theam', 'dark');
        }
      }
    }
  }
}
.cardButton {
  flex: 0 1 auto;
  position: relative;
  padding: 0 0 1rem 0;
  z-index: 9;
  @extend %flexbox;
  @extend %all-center;
  &__item {
    @extend %flexbox;
    @extend %all-center;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    span {
      flex: 0 0 auto;
      &:first-child {
        min-width: 1rem;
        min-height: 1rem;
        max-width: 1rem;
        max-height: 1rem;
        margin-right: 0.5rem;
        @extend %flexbox;
        @extend %all-center;
        svg {
          width: 100%;
          height: 100%;

          path {
            fill: theme-colors('theam', 'dark', 0.6);
          }
        }
      }
    }
    &.closeIcon {
      color: theme-colors('background', 'error', 0.6);
      span {
        color: theme-colors('background', 'error', 0.6);
        &:first-child {
          min-width: 0.75rem;
          min-height: 0.75rem;
          max-width: 0.75rem;
          max-height: 0.75rem;
          svg {
            padding: 0;
            path {
              fill: theme-colors('background', 'error', 0.6);
            }
          }
        }
      }
    }
  }
}
.lessonsLearned {
  background-color: theme-colors('theam', 'light', 0.08);
}

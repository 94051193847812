.mvs {
  .select-class-new {
    border: 1px solid #bac7d0;
    border-radius: 6px;
    background-color: #fff !important;
  }

  .select-class-multiple {
    border: 1px solid #bac7d0;
    border-radius: 6px;
    height: 10px;
    width: 100%;
    background-color: #fff;

    .ant-select-selector {
      overflow: auto;

      .ant-select-selection-overflow-item {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }

  .btn {

    &-edit,
    &-save,
    &-cancel {
      border: 2px solid #3c5dd6;
      border-radius: 8px;
      font-size: 14px;
      padding: 5px 10px;
      color: #fff;
      background-color: #3c5dd6;
      cursor: pointer;
    }
  }

  .historical {
    margin: 15px 0;
    width: 100%;

    &-label {
      cursor: pointer;
      width: 100%;
      padding: 8px;
      background-color: #c8e6fd;
      position: relative;
    }

    &-container-open {
      width: 100%;
      padding: 8px;
      height: 150px;
      overflow-y: scroll;
      background-color: #e3e3f4;
      transition: all 0.2s ease-in;
    }

    &-container-close {
      width: 100%;
      height: 0;
      overflow-y: scroll;
      background-color: e3e3f4;
      transition: all 0.2s ease-in;
    }
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }
}
.planning {
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 0.5rem 1rem 0.5rem 16px;
  }

  .select-class {
    box-shadow: none;
    border: solid 1px rgba(25, 61, 83, 0.3);
    border-radius: 2px;
  }

  textarea {
    padding: 5px;
    border: solid 1px rgba(25, 61, 83, 0.3);
  }
}
.mission {
  padding-left: 8px;

  .initalCardMission {
    padding: 80px 25px 80px 25px;
  }

  &_card {
    position: relative;
    background-color: white;
    border-radius: 15px;
    height: 340px;
    padding: 25px;
    padding-top: 15px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(12, 35, 120, 0.25);
    margin-top: 15px;

    &_circular {
      width: 80px;
      margin: auto;
    }

    &_name {
      color: #3c5dd6;
      font-size: 20px;
      margin: 8px 0;
    }

    &_option {
      width: 4px;
    }

    &_date {
      width: 80%;
      bottom: 10px;
      margin: auto 0;
    }
  }

  h2 {
    + h4 {
      color: #3c5dd6;
    }
  }
}

.mission-modal {
  .input-class {
    border: 1px solid #d0ebff;
    border-radius: 6px;
    height: 40px;
    width: 100%;
    padding: 5px;
    background-color: #fff;
  }

  .datePickerM {
    border: 1px solid #d0ebff;
    border-radius: 6px;
    height: 40px;
    padding: 5px;
    width: 100%;
  }
}

.completedTag,
.progressTag {
  font-size: 13px;
  color: black;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #a8eef8;
}

.completedTag {
  background-color: #bbdbc9;
  color: black;
}

.teamcard {
  &-card {
    position: relative;
    background-color: white;
    border-radius: 15px;
    padding-bottom: 15px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(12, 35, 120, 0.25);
    margin-top: 15px;
    &-option {
      width: 90%;
      margin: auto;
    }
  }
  .icon {
    width: 15px;
    height: 15px;
    &-container {
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }
  .influencer-tag {
    background-color: #258750;
    border-radius: 15px;
    color: white;
    padding: 5px;
  }

  .h-25px {
    height: 25px;
  }
}

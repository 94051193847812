.keydrivers {
  padding: 25px;
  &-container {
    background: linear-gradient(to right, #faad14a7 33%, #c8e6fd 33% 66%, #cacaec 33%);
    width: 100%;
    height: 700px;
    display: flex;
  }
  &-template {
    background-color: #3c5dd6;
    color: white;
    padding: 5px;
  }
  .w-33 {
    width: 33%;
  }
  .w-67 {
    width: 67%;
  }

  .overflow-y-scroll {
    overflow: scroll;
  }

  &-card,
  &-btn-new {
    width: 90%;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
  }

  &-btn-new {
    padding: 5px;
  }

  .bg-gray {
    background-color: #c8e6fd;
  }

  .bg-orange {
    background-color: #ce5829;
  }

  .top-15 {
    top: 15px;
  }

  .bottom-15 {
    bottom: 15px;
  }

  .h-760 {
    height: 760px;
  }

  .bg-blue {
    background-color: #3c5dd6;
  }
}

.keydriver-template-modal {
  .ant-modal-content {
    border-radius: 1rem !important;
  }

  .ant-modal-footer {
    border: none;
    gap: 0.5rem;
    justify-content: center !important;
  }
}
.textareadebounce {
  &.autoheight {
    resize: none;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 60px;
    max-height: 185px;
    transition: height 0.2s ease-in-out;
    height: auto;
  }
}
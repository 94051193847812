.ProjectsLIst {
  background-color: #b0b0d3;
  border-radius: 0 0 8px 8px;
  padding: 16px;

  .bordered {
    border: 1px solid #c7e6fd;
    min-height: 45px;
  }

  .capitalized {
    text-transform: capitalize;
  }

  &-container-data {
    border-radius: 8px;
    background-color: #fff;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 33px;
  }

  &-label-table {
    background-color: #0478c6;
    border-radius: 8px;
  }

  &-img {
    width: 15px;
  }

  &-circle-green,
  &-circle-yellow,
  &-circle-red {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #1ac47d;
    display: block;
    margin: auto;
  }

  .badge-IN_ACTION,
  .badge-CLOSED,
  .badge-CREATED,
  .badge-PLANNING,
  .badge-IN_PROGRESS,
  .badge-COMPLETED {
    display: inline-block;
    padding: 1px 7px;
    margin-top: 8px;
    color: #1ac47d;
    border: 1px solid #1ac47d;
    font-weight: normal;
    text-transform: capitalize;
  }

  .badge-CREATED {
    color: #000;
    border: 1px solid #fff0f6;
    background-color: #fff0f6;
  }

  .badge-CLOSED,
  .badge-PLANNING {
    color: #000;
    border: 1px solid #e6eef7;
    background-color: #e6eef7;
  }

  .badge-IN_PROGRESS {
    color: #fff;
    background-color: #1ac47d;
  }

  .badge-COMPLETED {
    color: #0478c6;
    border: 1px solid #0478c6;
  }

  &-circle-yellow {
    background-color: #faad14;
  }

  &-circle-red {
    background-color: #f5222d;
  }

  &-SprintItem {
    .ant-col {
      button {
        &:not(.primary) {
          min-height: 45px !important;
          border: 1px solid #0478c6;
          color: #0478c6 !important;
          background-color: #fff !important;
        }

        &.primary {
          min-height: 30px;
        }
      }
    }
  }

  .ant-row {
    &.row-black {
      background-color: #102531;
      border-radius: 8px 8px 0 0;
      padding: 5px 0;

      .ant-col {
        display: flex;
        align-items: center;
        color: #fff;

        span.subtitle {
          font-size: 12px;
          margin-left: 10px;
        }

        button {
          min-height: 25px !important;
        }
      }
    }

    &.row-white {
      border: 1px solid #0478c6;
      border-radius: 8px;

      .header {
        padding: 10px 0;
        margin-left: 10px;
        margin-right: -10px;
        border-bottom: 1px solid #0478c6;

        +.ant-col {
          margin-left: -10px;
          border-bottom: 1px solid #0478c6;

          button {
            min-height: 33px !important;
            margin-top: 4px;
          }
        }
      }

      .ant-col {
        span.subtitle {
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }

    &.row-primary {
      background-color: #71bae2;
      padding: 5px 0;
    }

    &.row-detailed {
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      margin-top: 0 !important;
      min-height: 50px;
    }

    .ant-col {
      button {
        &.ant-btn-primary {
          background-color: #0478c6;
          border-radius: 8px;
          padding: 0 5px;
          min-height: 33px;
          width: 100%;

          .anticon {
            font-size: 16px;
            line-height: 0px;
          }
        }
      }

      .ant-list {
        li.ant-list-item {
          padding: 0 0 0 8px;
          border-bottom: none;
          line-height: 18px;

          .anticon {
            margin-right: 5px;
          }
        }
      }

      b {
        display: inline-block;
      }
    }
  }
}
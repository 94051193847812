@import '../../styles/abstracts/controls';
.confirmationMessage {
  flex: 1 1 auto;
  padding: 3rem 0;
  background-color: theme-colors('background', 'white');
  @extend %flex-column;
  @extend %all-center;
  h3 {
    font-size: 22px;
    font-weight: 400;
    color: theme-colors('primary', 'base', 0.5);
  }
  &__list {
    margin-top: 2rem;
    flex: 1 1 auto;
    @extend %flexbox;
    @extend %all-center;
    li {
      flex: 0 0 auto;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

@import '../../../../styles/abstracts/controls';
.organizationDetails {
  margin: 0;
  // padding-top: 2rem;
  min-height: calc(100vh - 6rem);
  .organizationBreadcrumbs {
    margin-bottom: 2rem;
    @extend %flexbox;
    @extend %item-center;
  }
  // .organizationProfile {
  //   flex: 1 1 auto;
  //   &__content {
  //     padding-bottom: 1rem;
  //     border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
  //     @extend %flexbox;
  //     @extend %item-start;

  //     .profileBrand {
  //       @extend %flex-column;
  //       @extend %all-center;

  //       .contentImage {
  //         flex: 0 0 auto;
  //         max-width: 80px;
  //         max-height: 80px;
  //         min-width: 80px;
  //         min-height: 80px;
  //         padding: 5px;
  //         overflow: hidden;
  //         @extend %flex-column;
  //         @extend %all-center;
  //         @extend %radius-sm;
  //         @include box-shadow(
  //           0px 1px 14px theme-colors('primary', 'dark', 0.1)
  //         );
  //         img {
  //           max-height: 70px;
  //           min-height: 70px;
  //         }
  //       }
  //     }

  //     .profileCompany {
  //       padding-left: 1.5rem;
  //       @extend %flex-column;

  //       h2 {
  //         margin-top: 0;
  //         line-height: 2rem;
  //         margin-bottom: 0;
  //         color: theme-colors('primary', 'light');
  //       }

  //       .contentBtn {
  //         flex: 0 0 auto;
  //         margin-top: 0.2rem;
  //       }
  //     }
  //   }

  //   &__elements {
  //     flex: 0 1 auto;
  //     padding-bottom: 1rem;
  //     margin-top: 1rem;
  //     border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
  //     @extend %flexbox;
  //     @extend %item-center;

  //     li {
  //       flex: 0 0 auto;
  //       @extend %flexbox;
  //       @extend %item-center;

  //       &:not(:last-child) {
  //         margin-right: 1rem;
  //       }

  //       span {
  //         flex: 0 0 auto;
  //         line-height: 24px;
  //         @extend %flexbox;
  //         @extend %all-center;

  //         &:first-child {
  //           margin-right: 0.75rem;
  //           min-width: 24px;
  //           max-width: 24px;
  //           min-height: 24px;
  //           max-height: 24px;

  //           svg {
  //             width: 100%;
  //             height: 100%;

  //             path {
  //               fill: theme-colors('primary', 'base', 0.6);
  //             }
  //           }
  //         }

  //         &:last-child {
  //           font-weight: 600;
  //           min-height: 24px;
  //           max-height: 24px;
  //           padding: 0 0.75rem;
  //           border: 1px solid theme-colors('primary', 'base', 0.1);
  //           color: theme-colors('theam', 'dark');
  //           @extend %radius-sm;
  //         }
  //       }
  //     }
  //   }

  //   &__list {
  //     flex: 1 1 auto;
  //     margin: 0;
  //     @extend %flex-column;

  //     li {
  //       flex: 1 1 auto;
  //       padding: 1rem 0;
  //       font-size: 0.875rem;
  //       cursor: pointer;
  //       color: theme-colors('primary', 'base', 0.7);
  //       @include transition(all 0.4s ease-in-out);
  //       @extend %flex-column;

  //       &:not(:last-child) {
  //         border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
  //         margin-bottom: 2px;
  //       }

  //       a {
  //         flex: 1 1 auto;
  //         @extend %flexbox;
  //         @extend %item-center;

  //         span {
  //           flex: 0 0 auto;
  //           color: theme-colors('primary', 'base', 0.8);
  //           @extend %break-text;

  //           &:not(:last-child) {
  //             margin-right: 1rem;
  //           }

  //           &:first-child {
  //             margin-left: 0.5rem;
  //             min-width: 20px;
  //             max-width: 20px;
  //             min-height: 20px;
  //             max-height: 20px;

  //             svg {
  //               width: 100%;
  //               height: 100%;

  //               path {
  //                 fill: theme-colors('primary', 'base', 0.6);
  //               }
  //             }
  //           }
  //         }

  //         &:hover {
  //           color: theme-colors('theam', 'dark');
  //           @include transition(all 0.4s ease-in-out);

  //           span {
  //             flex: 0 0 auto;
  //             color: theme-colors('theam', 'dark') !important;

  //             &:first-child {
  //               padding: 0;

  //               svg {
  //                 width: 100%;

  //                 path {
  //                   fill: theme-colors('theam', 'dark') !important;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .organizationView {
    flex: 1 1 auto;
    // padding-left: 3.5rem;
    @extend %flex-column;

    @include media('<phone') {
      padding-left: 0;
    }

    @include media('>=phone', '<576px') {
      padding-left: 0;
    }

    @include media('>=576px', '<tablet') {
      padding-left: 0;
    }

    &__header {
      padding-bottom: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @extend %flex-column;
    }
    .missionFooter {
      flex: 1 1 auto;
      @extend %flexbox;
      @extend %all-center;
    }
  }
}
.addMission {
  height: 100%;
  padding: 1rem;
  cursor: pointer;
  @extend %flex-column;
  @extend %all-center;

  &__btn {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    background-color: theme-colors('background', 'green');
    @extend %radius-sm;
    @extend %flex-column;
    @extend %all-center;

    span {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      flex: 1 1 auto;
      @extend %flex-column;
      @extend %all-center;

      svg {
        width: 70%;
        height: 70%;

        path {
          fill: theme-colors('background', 'white');
        }
      }
    }
  }

  &__text {
    margin-top: 1rem;
    flex: 0 1 auto;
    @extend %flex-column;
    @extend %all-center;

    h3 {
      font-size: 22px;
      text-align: center;
      margin-top: 2px;
      color: theme-colors('primary', 'light', 0.8);
      margin: 0;
    }
  }
}

.missionDetails {
  flex: 1 1 auto;
  padding: 1rem 1rem 0 1rem;
  position: relative;
  cursor: pointer;
  z-index: 0;
  @extend %flex-column;
  @include media('>=992px', '<desktop') {
    padding: 0;
  }
  @include media('>=desktop', '<=1366px') {
    padding: 0;
  }

  &__brand {
    flex: 0 1 auto;
    @extend %flexbox;
    @extend %all-center;
    .brandIcon {
      flex: 0 0 auto;
      min-width: 80px;
      max-width: 80px;
      min-height: 80px;
      max-height: 80px;
      overflow: hidden;
      @extend %flexbox;
      @extend %all-center;
      span {
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
        @extend %flexbox;
        @extend %all-center;
        svg {
          width: 70%;
          height: 70%;
          path {
            fill: theme-colors('theam', 'light', 0.8);
          }
        }
      }
    }
  }

  &__data {
    flex: 1 1 auto;
    @extend %flex-column;
    h3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      cursor: pointer;
      text-align: center;
      margin-bottom: 1rem;
      color: theme-colors('primary', 'light', 0.8);
      @include media('<phone') {
        text-align: center;
      }

      @include media('>=phone', '<tablet') {
        text-align: center;
      }

      @include media('>=tablet', '<=992px') {
        text-align: center;
      }
    }

    .sprintOverview {
      @extend %flexbox;
      @extend %all-center;
      span {
        flex: 0 0 auto;
        &:not(:last-child) {
          margin-right: 1rem;
        }
        &:nth-child(2) {
          font-weight: 600;
          font-size: 13px;
          color: theme-colors('theam', 'dark');
        }
      }
    }
    .sprintNetworks {
      @extend %flexbox;
      @extend %all-center;
      span {
        flex: 0 0 auto;
        &:not(:last-child) {
          margin-right: 1rem;
        }
        &:nth-child(2) {
          font-weight: 600;
          font-size: 13px;
          color: theme-colors('theam', 'dark');
        }
      }
    }
    .dateRange {
      margin: 1rem 0;
      padding: 0px 2rem;
      @extend %flex-column;
      @include media('>=992px', '<desktop') {
        padding: 0 10px;
      }
      @include media('>=desktop', '<=1366px') {
        padding: 0 10px;
      }
      &__value {
        @extend %flexbox;
        @extend %item-center;
        @extend %justify-between;
        span {
          flex: 0 0 auto;
          text-align: center;
          font-weight: 600;
          font-size: 12px;
          color: theme-colors('theam', 'dark');
          @extend %flexbox;
          @extend %all-center;
          &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            margin-left: 10px;
          }
        }
      }
      &__label {
        @extend %flexbox;
        @extend %item-center;
        @extend %justify-between;
        span {
          flex: 0 0 auto;
          text-align: center;
          color: theme-colors('primary', 'light', 0.6);
          @extend %flexbox;
          @extend %all-center;
        }
      }
      &__bar {
        margin: 0.25rem 0;
        @extend %flex-column;
        .progressBar {
          width: 100%;
          position: relative;
          flex: 1 1 auto;
          min-height: 4px;
          max-height: 4px;
          overflow: hidden;
          background-color: theme-colors('primary', 'light', 0.1);
          @include border-radius(50px);
          &__element {
            position: absolute;
            left: 0;
            top: 0;
            min-height: 4px;
            max-height: 4px;
            @extend %progressbar-bg;
            @include border-radius(50px);
          }
        }
      }
    }
  }
}
.popover_tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.popover_icon {
  display: flex;
  align-items: center;
  width: 20px;
  margin-right: 1rem;
}
// .organizationProfile_content{
//   display: flex;
//   justify-content:flex-start;
//   gap: 10px;
// }
// .profileBrand{
//   // flex: 1;
//   height: 75px;
//   width: 50px !important;
// }
// .contentImage {
//   flex: 0 0 auto;
//   max-width: 80px;
//   max-height: 80px;
//   min-width: 80px;
//   min-height: 80px;
//   padding: 5px;
//   overflow: hidden;
//   @extend %flex-column;
//   @extend %all-center;
//   @extend %radius-sm;
//   @include box-shadow(
//     0px 1px 14px theme-colors('primary', 'dark', 0.1)
//   );
//   img {
//     max-height: 70px;
//     min-height: 70px;
//   }
// }

// .profileCompany {
//   // flex: 2;
// }

.organizationProfile {
  display: flex;
  flex: 1 1 auto;
  &__content {
    padding-bottom: 1rem;
    border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
    @extend %flexbox;
    @extend %item-start;

    .profileBrand {
      @extend %flex-column;
      @extend %all-center;

      .contentImage {
        flex: 0 0 auto;
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
        padding: 5px;
        overflow: hidden;
        @extend %flex-column;
        @extend %all-center;
        @extend %radius-sm;
        @include box-shadow(0px 1px 14px theme-colors('primary', 'dark', 0.1));
        img {
          max-height: 70px;
          min-height: 70px;
        }
      }
    }

    .profileCompany {
      padding-left: 1.5rem;
      @extend %flex-column;

      h2 {
        margin-top: 0;
        line-height: 2rem;
        margin-bottom: 0;
        color: theme-colors('primary', 'light');
      }

      .contentBtn {
        flex: 0 0 auto;
        margin-top: 0.2rem;
      }
    }
  }

  &__elements {
    flex: 0 1 auto;
    padding-bottom: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
    @extend %flexbox;
    @extend %item-center;

    li {
      flex: 0 0 auto;
      @extend %flexbox;
      @extend %item-center;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      span {
        flex: 0 0 auto;
        line-height: 24px;
        @extend %flexbox;
        @extend %all-center;

        &:first-child {
          margin-right: 0.75rem;
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;

          svg {
            width: 100%;
            height: 100%;

            path {
              fill: theme-colors('primary', 'base', 0.6);
            }
          }
        }

        &:last-child {
          font-weight: 600;
          min-height: 24px;
          max-height: 24px;
          padding: 0 0.75rem;
          border: 1px solid theme-colors('primary', 'base', 0.1);
          color: theme-colors('theam', 'dark');
          @extend %radius-sm;
        }
      }
    }
  }

  &__list {
    flex: 1 1 auto;
    margin: 0;
    @extend %flex-column;

    li {
      flex: 1 1 auto;
      padding: 1rem 0;
      font-size: 0.875rem;
      cursor: pointer;
      color: theme-colors('primary', 'base', 0.7);
      @include transition(all 0.4s ease-in-out);
      @extend %flex-column;

      &:not(:last-child) {
        border-bottom: 1px solid theme-colors('primary', 'base', 0.06);
        margin-bottom: 2px;
      }

      a {
        flex: 1 1 auto;
        @extend %flexbox;
        @extend %item-center;

        span {
          flex: 0 0 auto;
          color: theme-colors('primary', 'base', 0.8);
          @extend %break-text;

          &:not(:last-child) {
            margin-right: 1rem;
          }

          &:first-child {
            margin-left: 0.5rem;
            min-width: 20px;
            max-width: 20px;
            min-height: 20px;
            max-height: 20px;

            svg {
              width: 100%;
              height: 100%;

              path {
                fill: theme-colors('primary', 'base', 0.6);
              }
            }
          }
        }

        &:hover {
          color: theme-colors('theam', 'dark');
          @include transition(all 0.4s ease-in-out);

          span {
            flex: 0 0 auto;
            color: theme-colors('theam', 'dark') !important;

            &:first-child {
              padding: 0;

              svg {
                width: 100%;

                path {
                  fill: theme-colors('theam', 'dark') !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

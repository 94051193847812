.solutionsidebar {
  position: relative;
  .contact-us {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(61, 61, 61, 0) 0%, #393939 20%, #000000 34.17%);
    display: flex;
    justify-items: center;
    align-items: center;
    position: absolute;
    top: 0;
    text-align: center;
    padding: 15px;
  }
  .bg-black-gradient {
    background-image: linear-gradient(180deg, #000000 0%, #3d3d3d 100%);
  }
}

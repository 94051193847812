@import '../../styles/abstracts/controls';

.mainWrapper {
  margin: 0;
  min-height: 100vh;
  @extend %flex-column;
  .bodyContent {
    width: 100%;
    flex: 1 1 auto;
  }
}

.header-text {
    display: hidden;
    background-color: white;
    color: white;
}

.card-event {
    background-color: #4DA4DA;
    color: white;
    border: none;
    font-size: 15px;
}

.calendar {
    background-color: transparent !important;
}

.fc-day-today,
.fc-day {
    background-color: transparent !important;
}

.fc-day-today {
    background-color: transparent !important;
}

.fc-timegrid-slot-label {
    background-color: transparent;
    border: none !important;
}

.calendar-container {
    border-radius: 15px;
    background-color: white;
}

.upcoming-meetings {
    background-color: #fff;
    border-radius: 8px;
    height: 64vh;
    width: 90%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.create-meeting {
    text-align: center;
    vertical-align: middle;
    margin: auto;
    &-button {
        cursor: pointer;
        padding: 8px;
        background: #3c5dd6;
        color: #fff;
        border-radius: 39.5px;
        border: none;
        width: 80%;
        height: auto;
        box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
        transition: 0.7s;
    }
    &-button:hover {
        background: #152250;
        transition: 0.7s;
    }
}

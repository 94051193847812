@import "../../../../../styles/abstracts/controls";
.membermodal,
.closeModal {
  height: 100vh;
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  transform: translateX(0);
  transition: transform 0.2s ease-in;
  overflow-y: scroll;
  .opc-5 {
    opacity: 0.5;
  }
  .top-16 {
    top: 16px;
  }

  .left-115 {
    left: 115%;
  }

  .tabs {
    padding: 8px 10px 2px 10px;
    background-color: #ecf7ff;
    border-radius: 15% 15% 0 0;
  }
  .deleteIcon {
    color: red;
    font-size: 18px;
    margin-left: 12px;
  }
  .textarea {
    border: 3px solid #d9efff;
    border-radius: 15px;
    resize: none;
    height: 100px;
  }

  .closeIcon {
    top: 0;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  .border-error {
    border-bottom: 1px solid rgba(255, 0, 0, 0.281);
  }

  .input-class {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.057);
    width: 80%;
    padding: 5px;
    background-color: #fff;
  }

  form {
    button,
    .delete-btn {
      cursor: pointer;
      padding: 8px;
      background: #3c5dd6;
      color: #fff;
      border: none;
      height: auto;
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
      transition: 0.5s;
      margin: 25px 0;
    }
    .delete-btn {
      background: #888;
    }
    .ant-select-selector {
      padding: 0 !important;
    }

    .p-5 {
      padding: 5;
    }
  }

  .border-rounder {
    border-radius: 50%;
  }

  .avatar-position {
    bottom: -50px;
    left: 15px;
  }

  .avatar {
    width: 98px;
    height: 98px;
    &:hover {
      .editOverlay {
        height: 50%;
        opacity: 1;
        @include transition(all 0.4s ease-in-out);
      }
    }
  }
  .editOverlay {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    background-color: theme-colors("primary", "dark", 0.5);
    color: bisque;
    @extend %flexbox;
    @extend %all-center;
    @include transition(all 0.8s ease-in-out);
  }

  .ant-select-selection-placeholder {
    color: #8a949a;
  }
}

.modal-delete-btn,
.modal-dont-delete {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #3c5dd6;
  color: #3c5dd6;
  padding: 8px;
}

.modal-delete-btn {
  &:hover {
    background-color: rgba(191, 191, 191, 0.105);
  }
}

.modal-dont-delete {
  background-color: #3c5dd6;
  color: #fff;
  &:hover {
    background-color: #2b4399;
  }
}

.w-80-animate {
  width: 80% !important;
  transition: all 0.2s ease-in;
}

.w-60-animate {
  width: 60% !important;
  transition: all 0.2s ease-in;
}

.w-50-animate {
  width: 50% !important;
  transition: all 0.2s ease-in;
}

.closeModal {
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
}

.text-end {
  text-align: end;
}

.bg-modal-click {
  position: fixed;
  width: 100%;
  height: 100%;
  background: red;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.closed {
  .btn {
    &-edit,
    &-save,
    &-cancel {
      border: 2px solid #3c5dd6;
      border-radius: 8px;
      font-size: 14px;
      padding: 5px 10px;
      color: #fff;
      background-color: #3c5dd6;
      cursor: pointer;
    }
  }
}

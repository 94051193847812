@import '../../styles/abstracts/controls';

.addInitialCard {
  flex: 1 1 auto;
  min-height: 300px; //450px;
  overflow: hidden;
  @extend %flexbox;
  @extend %all-center;
  @extend %radius-xl;
  &__block {
    flex: 0 1 auto;
    padding: 1rem 1rem 0 1rem;
    @extend %flex-column;
    h3 {
      margin-bottom: 0;
      font-size: 2rem;
      margin-bottom: 1rem;
      text-align: center;
      color: theme-colors('primary', 'base', 0.6);
    }
    p {
      margin-bottom: 0;
      font-size: 1rem;
      text-align: center;
      color: theme-colors('primary', 'base', 0.6);
    }
    .initialFooter {
      margin-top: 2rem;
      flex: 0 1 auto;
      padding-top: 1rem;
      @extend %flexbox;
      @extend %all-center;
      a {
        border: solid 1px theme-colors('primary', 'light', 0.1);
        padding: 0.5rem 1rem;
        @extend %radius-sm;
        @extend %flexbox;
        @extend %all-center;
        span {
          flex: 0 0 auto;
          &:first-child {
            margin-right: 0.75rem;
            min-width: 1rem;
            max-width: 1rem;
            min-height: 1rem;
            max-height: 1rem;
            @extend %flexbox;
            @extend %all-center;
            svg {
              width: 100%;
              height: 100%;
              path {
                fill: theme-colors('theam', 'dark');
              }
            }
          }
        }
      }
    }
  }
}

.intel {
  padding: 25px;
  margin: auto;
  .tabs {
    background-color: red;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #b7e0ff;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    transform: translateY(-1px) !important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    background-color: #e3e3f4;
    border-radius: 12px 12px 0px 0px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #4da3d9 !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
  }
  .ant-tabs-tab:hover {
    background-color: #4da3d9 !important;
    color: #fff;
  }
  .select-class {
    border: 1px solid #ecf7ff;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    width: 300px;
    background-color: #fff !important;
    margin-bottom: 15px !important;
  }
}

.membercard {
    display: flex;
    .avatar {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin: 0 15px;
    }
    .memberData {
        display: flex;
        flex-direction: column;
        .name {
            font-size: 14px;
        }
        .designation {
            font-size: 12px;
            color: #a1a1a1;
        }
    }
    .flex {
        display: flex;
    }

    .custom-vertical-align {
        justify-content: center;
        align-items: center;
    }

    .mt-10 {
        margin-top: 10px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #D9D9D9;
        border-color: #D9D9D9;
    }
}

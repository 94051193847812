.pftsteps {
  .bg-orange {
    background-color: #ce5829 !important;
  }
  .delete-icon-position {
    right: 30px;
    top: 50%;
  }
  .w-225px {
    width: 225px;
  }
  .input-class {
    border: 1px solid #bac7d0 !important;
    border-radius: 6px !important;
    height: 40px !important;
    padding: 5px !important;
    box-shadow: none !important;
    background-color: #fff !important;
  }
}

@import '../abstracts/controls';
[class^='ant-'],
[class*='ant-'] {
  font-weight: 400;
}
.ant-row {
  padding: 0;
  // .ant-col {
  //   margin-bottom: 1rem;
  // }
}
.ant-collapse {
  border: 0;
  background-color: transparent;
  @extend %flex-column;

  .ant-collapse-item {
    border: 0;
    flex: 0 1 auto;
    margin: 1px 0;
    background-color: transparent;
    @extend %flex-column;

    .ant-collapse-header {
      padding: 0.5rem 1rem 0.5rem 2.5rem;
      background-color: theme-colors('primary', 'light', 0.06);

      .ant-collapse-arrow {
        top: 50%;
        padding: 0;
        @include transform(translate(0, -50%));
      }
    }

    .ant-collapse-content {
      border: solid 1px theme-colors('primary', 'light', 0.06);

      .ant-collapse-content-box {
        padding: 0.5rem;
      }
    }
  }
}
/*--- form common ---*/
.ant-btn,
.ant-input,
.ant-picker {
  @extend %radius-xs;
}
.ant-input,
.ant-picker {
  min-height: 38px;
  border: solid 1px theme-colors('primary', 'light', 0.3);
  @include transition(all 0.4s ease-in-out);
  &:hover {
    border-color: theme-colors('theam', 'dark');
    color: theme-colors('theam', 'dark');
    @include transition(all 0.4s ease-in-out);
    @include box-shadow(0 0 0 transparent);
  }
}
/*--- Select ---*/
.ant-select {
  flex: 0 1 auto;
  min-width: 180px;
  min-height: 38px;
  position: relative;
  @extend %flex-column;
  .ant-select-arrow {
    cursor: pointer;
    top: 50%;
    padding: 0;
    margin: 0;
    @extend %flexbox;
    @extend %all-center;
    @include transform(translate(0, -50%));
    .anticon {
      color: theme-colors('primary', 'light', 0.5);
      width: 21px;
      margin-left: 0px;
    }
  }

  &.ant-select-focused {
    background-color: transparent;
    .ant-select-selector {
      outline: 0;
      @include box-shadow(0, 0, 0, rgba(255, 255, 255, 0) !important);
    }
  }
  &.ant-select-single {
    margin: 0;
    &:not(.ant-select-customize-input) {
      background-color: transparent;
      .ant-select-selector {
        min-height: 38px;
        border: solid 1px theme-colors('primary', 'light', 0.3);
        @include transition(all 0.4s ease-in-out);
        .ant-select-selection-search {
          flex: 0 0 auto;
          .ant-select-selection-search-input {
            min-height: 38px;
            font-weight: 300;
          }
        }
        .ant-select-selection-item {
          flex: 1 1 auto;
          line-height: 37px;
        }
      }
    }
  }
  &.ant-select-multiple {
    margin: 0;
    .ant-select-selector {
      padding: 0 8px;
      min-height: 38px;
      border: solid 1px theme-colors('primary', 'light', 0.3);
      @include transition(all 0.4s ease-in-out);
      .ant-select-selection-overflow {
        @extend %flexbox;
        @extend %item-center;
      }
    }
  }
}
/*--- input ---*/
.ant-input {
  margin: 0;
  color: theme-colors('primary', 'base', 0.8);
  @include placeholder {
    color: theme-colors('primary', 'base', 0.8);
  }
  &.login-input {
    min-height: 46px;
    padding: 0 1rem;
  }
  &.search-input {
    padding-left: 50px;
  }
  &:focus,
  &:hover {
    margin: 0;
    outline: 0;
    @include box-shadow(0 0 0 transparent);
  }
  &:focus {
    @include transition(all 0.4s ease-in-out);

    @include placeholder {
      color: transparent;
      @include transition(all 0.4s ease-in-out);
    }
  }
  &.textarea-item {
    min-height: 150px;
    max-height: 150px;
  }
}
/*--- input password ---*/
.ant-input-affix-wrapper {
  border: solid 1px theme-colors('primary', 'light', 0.3);
  &.ant-input-password {
    padding: 0;
    min-height: 46px;
    position: relative;
    @extend %radius-sm;
    @extend %flexbox;
    @extend %item-center;
    .ant-input-suffix {
      margin: 0;
      padding: 0;
      right: 1rem;
      top: 50%;
      position: absolute;
      @include transform(translateY(-50%));
    }
  }
  &:focus,
  &:hover {
    outline: 0;
    color: theme-colors('theam', 'light');
    border-color: theme-colors('theam', 'light');
    @include box-shadow(0 0 0 transparent);
    @include transition(all 0.4s ease-in-out);
  }
  &:not(.ant-input-affix-wrapper-disabled):focus,
  &:not(.ant-input-affix-wrapper-disabled):hover {
    outline: 0;
    border-color: theme-colors('theam', 'dark');
    color: theme-colors('theam', 'dark');
    @include transition(all 0.4s ease-in-out);
    @include box-shadow(0 0 0 transparent);
  }
  input.ant-input {
    height: 100%;
    min-height: 44px;
    padding: 0 1rem;
    &:focus {
      @include box-shadow(
        0 0 1000px theme-colors('background', 'white') inset !important
      );
    }
  }
}

/*--- button ---*/
.ant-btn {
  min-height: 38px;
  text-transform: uppercase;
  outline: 0;
  font-size: 12px;
  @extend %flexbox;
  @extend %all-center;
  @include transition(all 0.4s ease-in-out);
  &[disabled] {
    background-color: theme-colors('primary', 'base', 0.2) !important;
    color: theme-colors('primary', 'base', 0.4) !important;
    &:hover {
      color: theme-colors('primary', 'base', 0.4) !important;
    }
  }
  &:not(:last-child) {
    margin-right: 1rem;
  }
  &.password-btn {
    height: 46px;
    min-width: 60%;
    color: theme-colors('background', 'white');
    border: 0;
    font-size: 1rem;
  }
  &.login-btn {
    height: 46px;
    width: 100%;
    border: 0;
    font-size: 1rem;
    background-color: theme-colors('theam', 'base') !important;
    @include box-shadow(0px 20px 40px theme-colors('theam', 'base', 0.3));
  }
  &.ant-btn-primary {
    border: 0;
    color: theme-colors('background', 'white');
    background-color: theme-colors('theam', 'light');
  }
  &:focus {
    outline: 0;
    @include box-shadow(0 0 0 transparent);
  }
  &.done-button {
    padding: 0;
    margin-left: 15px;
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    border: 0;
    background-color: theme-colors('theam', 'base');
    svg {
      width: 50%;
      height: 50%;

      path {
        fill: theme-colors('background', 'white');
      }
    }
  }

  &.mission-btn,
  &.create-mission,
  &.organisation-btn {
    min-height: 22px;
  }
}

/* ------ Maodel window -------*/
.ant-modal-wrap {
  height: 100vh;
  overflow: hidden;
  @extend %flexbox;
  @extend %all-center;
  @include transition(all 0.4s ease-in-out);
  @include animation('fadeIn 0.8s');

  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
      @include transform(scale(0));
    }

    100% {
      opacity: 1;
      @include transform(scale(1));
    }
  }
  .ant-modal {
    top: 0;
    flex: 0 0 auto;
    max-width: 80%;
    margin: 0px !important;
    padding: 0 !important;
    @extend %flex-column;
    @include media('<phone') {
      min-width: 80%;
      max-width: 80%;
    }
    @include media('>=phone', '<576px') {
      min-width: 80%;
      max-width: 80%;
    }
    @include media('>=576px', '<tablet') {
      min-width: 90%;
      max-width: 90%;
    }
    @include media('>=tablet', '<=992px') {
      min-width: 90%;
      max-width: 90%;
    }
    &.measurement-model {
      width: 1000px;
      @include media('<phone') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=phone', '<576px') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=576px', '<tablet') {
        min-width: 90%;
        max-width: 90%;
      }
      @include media('>=tablet', '<=992px') {
        min-width: 90%;
        max-width: 90%;
      }
    }
    &.evidence-modal {
      width: 1000px !important;
      @include media('<phone') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=phone', '<576px') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=576px', '<tablet') {
        min-width: 90%;
        max-width: 90%;
      }
      @include media('>=tablet', '<=992px') {
        min-width: 90%;
        max-width: 90%;
      }
    }
    &.conform-login {
      width: 500px !important;
      @include media('<phone') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=phone', '<576px') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=576px', '<tablet') {
        min-width: 90%;
        max-width: 90%;
      }
      @include media('>=tablet', '<=992px') {
        min-width: 90%;
        max-width: 90%;
      }
    }
    &.password-model {
      width: 400px !important;
      @include media('<phone') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=phone', '<576px') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=576px', '<tablet') {
        min-width: 90%;
        max-width: 90%;
      }
      @include media('>=tablet', '<=992px') {
        min-width: 90%;
        max-width: 90%;
      }
    }
    &.addnew-window {
      width: 1000px !important;
      @include media('<phone') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=phone', '<576px') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=576px', '<tablet') {
        min-width: 90%;
        max-width: 90%;
      }
      @include media('>=tablet', '<=992px') {
        min-width: 90%;
        max-width: 90%;
      }
    }
    &.add-member {
      width: 600px !important;
      @include media('<phone') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=phone', '<576px') {
        min-width: 80%;
        max-width: 80%;
      }
      @include media('>=576px', '<tablet') {
        min-width: 90%;
        max-width: 90%;
      }
      @include media('>=tablet', '<=992px') {
        min-width: 90%;
        max-width: 90%;
      }
    }

    .ant-modal-content {
      outline: 0;
      overflow: hidden;
      @extend %radius-md;
      @extend %flex-column;
      .ant-modal-close {
        position: absolute;
        right: 0px;
        top: 10px;
        z-index: 99;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-color: theme-colors('background', 'white', 0.5);
        @include transform(translate(-50%, 0));
        @extend %flexbox;
        @extend %all-center;
        .ant-modal-close-x {
          flex: 0 0 auto;
          line-height: 20px !important;
          width: auto !important;
          height: auto !important;
        }
      }
      .ant-modal-header {
        flex: 0 1 auto;
        @extend %flexbox;
        @extend %item-center;
        .ant-modal-title {
          font-size: 1.256rem !important;
          font-weight: 400;
          color: theme-colors('primary', 'base', 0.6);
        }
      }
      .ant-modal-body {
        flex: 1 1 auto;
        overflow: auto;
      }
      .ant-modal-footer {
        flex: 0 1 auto;
        @extend %flexbox;
        @extend %item-center;
        @extend %justify-end;
      }
    }
  }
}
.ant-picker {
  flex: 0 0 auto;
  .ant-picker-input {
    margin: 0;
    input {
      font-size: 0.875rem !important;
      font-weight: 400 !important;
    }
  }
  &.mission-picker {
    max-width: 135px;
  }
  &.mission-picker1 {
    width: 100%;
      border-radius:5px !important;
      margin-top:5px;
      height:20px !important;
  }
}
/* ------ Checkbox -------*/
.ant-checkbox-wrapper {
  @extend %flexbox;
  @extend %all-center;
  .ant-checkbox {
    top: auto;
    .ant-checkbox-inner {
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      &:after {
        top: 7px;
        left: 4px;
        width: 6px;
        height: 12px;
      }
    }
  }
}

div.feedback {
  div.ant-alert {
    div.ant-alert-message {
      text-align: center;
      font-weight: 600;
      cursor: pointer;
    }
  }

  &-modal {
    input.ant-input {
      min-height: 30px;
    }

    div.ant-tabs-content-holder {
      div.ant-tabs-tabpane {
        h2 {
          text-align: center;
        }

        >p,
        >div {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }
  }
}
.createmeeting {
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;

    form {
        position: relative;
        label {
            font-size: 15px;
        }

        .select-class {
            padding: 0;
        }
        button {
            cursor: pointer;
            padding: 8px;
            background: #3c5dd6;
            color: #fff;
            border: none;
            width: 100px;
            height: auto;
            box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
            transition: 0.5s;
            margin: 15px 10px;
        }
        button:hover {
            background: #152250;
            transition: 0.5s;
        }

        .plusIconVertical,
        .plusIconHorizontal,
        .closeIconLeft,
        .closeIconRight {
            cursor: pointer;
            position: absolute;
            top: 40%;
            width: 5px;
            height: 20px;
            background-color: rgb(207, 207, 207);
            transform: rotate(0) translate(-50%, -50%);
            transform-origin: top left;
            content: "";
            transition: all 0.2s ease-in;
        }
        .plusIconHorizontal {
            transform: rotate(-90deg) translate(-50%, -50%);
        }

        .closeIconLeft {
            transform: rotate(45deg) translate(-50%, -50%);
        }
        .closeIconRight {
            transform: rotate(-45deg) translate(-50%, -50%);
        }

        .close-icon {
            color: #000;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            border: #000 solid 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            cursor: pointer;
        }
        .align-baseline {
            align-items: baseline;
        }
        .container-button {
            text-align: end;
        }
    }
}

.meetingscomments {
  margin-top: 50px;
  background-color: white;
  .hr-color {
    border: 1px solid #4da4da;
  }
  .textarea {
    border-radius: 10px;
    width: 100%;
    border: 1px solid #d0d0d0;
    height: 200px;
    margin-top: 0;
  }
  .m-0 {
    margin: 0;
  }
  form {
    button {
      cursor: pointer;
      border-radius: 8px;
    }
  }
  .ml-5 {
    margin-left: 5px;
  }
  .vertical-center {
    align-items: baseline;
  }

  .input-class,
  .team-members,
  .select-class,
  .select-class-multiple {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    padding: 5px;
    background-color: #fff;
  }

  .select-class-multiple {
    height: auto;
  }

  .vertical {
    justify-content: center;
    align-items: center;
  }
  .close-icon {
    color: #000;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: #000 solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
  }
}

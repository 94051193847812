.FormTable {
  div.container {
    background-color: #fff !important;

    &.design-table,
    &.design-undefined {}

    &.design-list {}

    div.ant-row {
      +div.ant-row {
        padding-top: 0.5rem;
      }

      div.ant-col {
        &+div.ant-col>*:not(.borderArrayIcon) {
          margin-left: 0.5rem;
          width: calc(100% - 0.5rem) !important;
        }

        &.flex {
          align-items: center;
          justify-content: center;

        }

        button {
          &:disabled {
            opacity: 0.2;
          }
        }
      }
    }
  }

  .select-class-multiple-table {
    border: 1px solid #bac7d0;
    border-radius: 6px;
    width: 100%;
    padding: 5px;
    background-color: #fff;
  }
}

button.borderArrayIcon {
  &.minus {
    background-color: #696969;
    color: #ffffff;
  }

  &.plus {
    border-color: #3C5DD6;
    color: #3C5DD6;
  }
}
.selectedmember {
    display: flex;
    border-radius: 50px;
    padding: 5px;
    margin: 2px;
    position: relative;
    padding-right: 25px;

    .avatar {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin: 0 5px;
    }

    .deleteIcon {
        position: absolute;
        right: 10px;
        cursor: pointer;
        color: #000;
    }
}

.sharemodal {
  > * {
    width: 100%;

    div.flex {
      input.share-input {
        border: none;
        padding: 8px;
        border-bottom: 1px solid #c8e6fd;
      }
    }
  }
}

.email-dropdown {
  .ant-select-dropdown {
    display: none !important;
  }
}

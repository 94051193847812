@import '../../../styles/abstracts/controls';

.formLabel {
    flex: 0 0 auto;
    @extend %flexbox;
    @extend %item-center;
    .requiredStar {
      flex: 0 0 auto;
      font-size: 1rem;
      min-width: 14px;
      max-width: 14px;
      max-height: 14px;
      max-width: 14px;
      color: theme-colors('background', 'error');
      @extend %flexbox;
      @extend %all-center;
      svg {
        width: 55%;
        height: 55%;
        path {
          fill: theme-colors('background', 'error');
        }
      }
    }
    .requiredName {
      flex: 1 1 auto;
      font-weight: 400;
      font-size: 0.857rem;
    }
  }
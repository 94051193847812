.preparingmodal {
  .ant-modal-header {
    background: #f5f5f5;
  }

  .border-error {
    border-color: red !important;
  }

  .ant-modal-title {
    color: #000 !important;
  }

  .ant-modal-body {
    padding: 0 24px;
  }

  .ant-picker {
    padding: 0;
  }
  .input-class,
  .team-members,
  .select-class,
  .select-class-multiple {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    padding: 5px;
    background-color: #fff;
  }

  .select-class-multiple {
    height: auto;
  }

  .datePicker {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    padding: 5px;
  }

  .vertical {
    justify-content: center;
    align-items: center;
  }

  .vertical-center {
    align-items: baseline;
  }

  .container-button {
    button {
      cursor: pointer;
      padding: 8px;
      background: transparent;
      color: #3C5DD6;
      border: 1px solid #3C5DD6;
      width: 100px;
      height: auto;
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
      transition: 0.5s;
      margin: 15px 0;
    }
    button:hover {
      background: #3C5DD6;
      color: #fff;
      transition: 0.5s;
    }
  }
}

.personas {
  div.row-container {
    display: block;

    div.ant-col {
      button {
        display: inline-block;
      }

      div.no-data {
        background-color: #ffffff;
        padding: 60px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        font-weight: 200;
      }
    }
  }
}
@import "../../../../../../../styles/abstracts/controls";

.smartsolutions {
  &-container {
    margin-top: 15px;
    min-height: 450px;
    overflow-y: auto;

    input {
      border: 1px solid #c8e6fd;
      padding: 2px;
      border-radius: 5px;
    }

    .ant-collapse {
      &-content-box {
        padding: 0 !important;
      }

      &-header {
        padding-left: 16px !important;
      }

      &.secondary {
        .ant-collapse-header {
          background-color: #fff;
          border-bottom: 1px solid #4da3d9;

          * {
            color: #4da3d9;
          }
        }
      }

      &-content {
        border: none !important;

        .flex>div {
          &+div:not(.hidden) {
            margin-left: 10px;
            min-width: calc(50% - 5px);
          }

          &.hidden {
            display: none !important;
          }

          background-color: theme-colors("background", "white") !important;
          margin: 15px 0;
          padding: 15px;
          border-radius: 5px;
        }
      }
    }

    .pageLoader {
      width: 100%;
      height: 100%;
      @extend %flexbox;
      @extend %all-center;

      &__inner {
        background-color: theme-colors("background", "white");
        padding: 10px;
        @extend %flex-column;
        @extend %all-center;
        @include border-radius(8px);

        .loading {
          width: 150px;
          height: 2px;
          position: relative;
          overflow: hidden;
          flex: 1 1 auto;
          background-color: theme-colors("primary", "base", 0.2);

          &:before {
            content: "";
            width: 75px;
            height: 2px;
            position: absolute;
            left: -34px;
            background: #0073b1;
            animation: loadBar 1.5s infinite ease;
            @include animation("loader-inner 0.8s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite");

            @include keyframes(loader-inner) {
              50% {
                left: 100px;
              }
            }
          }
        }
      }
    }
  }

  &-table {
    >div {
      +div {
        margin-left: 15px;
      }
    }

    div.table-caption {
      background-color: theme-colors("background", "white");
      padding: 15px;
      text-align: right;

      *+* {
        margin-left: 5px;
      }
    }

    tbody>tr {
      &.selected {
        >td {
          background-color: theme-colors("primary", "dark", 0.5) !important;
        }

        * {
          color: theme-colors("background", "white");
        }
      }
    }

    button {
      &.ant-btn-default {
        &.active {
          border-radius: 5px 5px 0 0;
        }

        border-radius: 5px;
        border-color: theme-colors("theam", "dark");
        color: theme-colors("theam", "dark");
      }

      &.embed {
        margin: -15px -15px 15px;
        display: flex;
        justify-content: flex-end;
        color: theme-colors("primary", "dark");

        &.w-100 {
          width: calc(100% + 30px) !important;
        }
      }
    }

    p.date {
      font-size: 10px;
    }
  }

  &-panel {
    >div>b {
      color: theme-colors("primary", "dark") !important;
    }

    p+p,
    p+b {
      margin-top: 15px;
      display: block;
    }
  }
}
.OverviewCard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  height: 112px;
  background: #ffffff;
  box-shadow: 5px 5px 21px rgba(13, 8, 44, 0.13);
  border-radius: 8px;

  .quantity-color {
    color: #006f7e;
  }
}

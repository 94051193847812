.teams {
  padding: 25px;
  margin: auto;
  height: 100vh;
  overflow: hidden;
  .select-class {
    border: 1px solid #ecf7ff;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    width: 60%;
    background-color: #fff !important;
  }

  .border-r-50 {
    border-radius: 50px;
  }

  .ant-table-thead {
    background-color: #fff !important;
    border-radius: 10px;
    margin-bottom: 50px;
  }
}

.observations,
.demographic,
.mvop {
  display: flex;
  .title {
    background-color: #111e62;
    margin-bottom: 15px;
  }

  .btn {
    &-edit,
    &-save,
    &-cancel {
      border: 2px solid #3c5dd6;
      border-radius: 8px;
      font-size: 14px;
      padding: 5px 10px;
      color: #fff;
      background-color: #3c5dd6;
      cursor: pointer;
    }
  }

  .w-49 {
    width: 49%;
  }

  .textarea {
    border: 1px solid #c8e6fd;
    border-radius: 6px;
  }

  .historical {
    height: 250px;
    overflow: auto;
  }

  .historical-card {
    background-color: #e3e3f4;
    border-radius: 12px;
    width: 100%;
    padding: 10px;
  }

  .hr-color {
    border-top: 1px solid #4da3d9;
  }

  .border-error {
    border-color: red !important;
  }
}

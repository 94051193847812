.overview {
  display: flex;
  .title {
    background-color: #4da3d9;
    margin-bottom: 15px;
  }
  .add-button-container {
    text-align: center;
    padding: 5px;
    border: 1px blue solid;
    border-radius: 5px;
    color: blue;
    margin-top: 20px;
    cursor: pointer;
  }
  .btn {
    &-edit,
    &-save,
    &-cancel {
      border: 2px solid #3c5dd6;
      border-radius: 8px;
      font-size: 14px;
      padding: 5px 10px;
      color: #fff;
      background-color: #3c5dd6;
      cursor: pointer;
    }
  }

  .w-49 {
    width: 49%;
  }

  .textarea {
    border: 1px solid #c8e6fd;
    border-radius: 6px;
  }

  .historical {
    height: 250px;
    overflow: auto;
  }

  .historical-card {
    background-color: #e3e3f4;
    border-radius: 12px;
    width: 100%;
    padding: 10px;
  }

  .hr-color {
    border-top: 1px solid #4da3d9;
  }

  .border-error {
    border-color: red !important;
  }

  .ant-collapse-content {
    background-color: transparent !important;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 0.5rem 1rem 0.5rem 16px;
    background-color: #4da3d9;
  }

  .input-class,
  .team-members,
  .select-class,
  .select-class-multiple {
    border: 1px solid #bac7d0 !important;
    border-radius: 6px !important;
    height: 40px !important;
    padding-left: 2px;
    box-shadow: none !important;
    background-color: #fff !important;
  }

  .select-class {
    padding: 0 !important;
    width: 100% !important;
  }
  .documents-container {
    height: 65px;
    overflow-y: auto;
  }

  .h-72 {
    height: 72px;
  }

  .w-100px {
    width: 100px;
  }
}

@import '../../styles/abstracts/controls';
.pagination-block {
  padding: 1rem 0;
  @extend %flex-column;
  .ant-pagination {
    flex: 1 1 auto;
    height: 40px;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-end;
    @extend %radius-xl;
    .ant-pagination-total-text {
      margin-right: auto;
    }
    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
      border: 0;
      flex: 0 0 auto;
      padding: 0px;
      height: 30px;
      width: 30px;
      overflow: hidden;
      background-color: theme-colors('background', 'white');
      border: 0;
      @extend %flexbox;
      @extend %all-center;
      @extend %radius-sm;
      @include transition(all 0.8s ease-in-out);
      a,
      .ant-pagination-item-link {
        flex: 0 0 auto;
        height: 30px;
        width: 30px;
        line-height: 30px;

        margin: 0px;
        padding: 0px 8px;
        background-color: transparent;
        border: 0;
        @extend %flexbox;
        @extend %all-center;
        @include border-radius(3px);
      }
      &:hover {
        border: theme-colors('theam', 'dark') solid 1px;
        @include transition(all 0.4s ease-in-out);
        a {
          color: theme-colors('theam', 'dark');
          font-weight: 400;
          @include transition(all 0.4s ease-in-out);
        }
      }
      &.ant-pagination-disabled {
        color: theme-colors('primary', 'light', 0.3);
        &:hover {
          border: 0 !important;
        }
      }
      &.ant-pagination-item-active {
        border: 0;
        border: theme-colors('theam', 'dark') solid 1px;
        a {
          color: theme-colors('theam', 'dark');
          font-weight: 600;
        }
      }
    }
  }
}

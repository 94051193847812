.solution-modal .ant-modal-content {
  height: 100%;
  position: relative;
  background-color: #090909;
  .anticon-close {
    display: none;
  }
  .close-icon {
    border: none;
  }
}


.custom-modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 10px;
  position: absolute;
  top: 5;
  right: 0;
  width: 100%;
  z-index: 1001;
  .minimize-icon,
  .close-icon {
    font-size: 18px;
    color: #303637;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
  }
}

.minimize-icon {
  margin-right: 10px;
}

.solution-iframe {
  background-color: black;
  width: 100%;
  height: 100%;
  border: none;
}

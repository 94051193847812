.historical {
  .historical {
    margin: 15px 0;
    width: 100%;

    &-label {
      cursor: pointer;
      width: 100%;
      padding: 8px;
      background-color: #c8e6fd;
      position: relative;
    }

    &-container-open {
      width: 100%;
      padding: 8px;
      height: 150px;
      overflow-y: scroll;
      background-color: #e3e3f4;
      transition: all 0.2s ease-in;
    }

    &-container-close {
      width: 100%;
      height: 0;
      overflow-y: scroll;
      background-color: e3e3f4;
      transition: all 0.2s ease-in;
    }
  }
}